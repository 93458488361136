import { googleLogout, useGoogleOneTapLogin } from "@react-oauth/google";
import { ReactNode, createContext, useEffect, useMemo, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router";

export interface IGoogleEndPointResponse {
  iss: string;
  sub: string;
  azp: string;
  aud: string;
  iat: string;
  exp: string;
  name: string;
  email: string;
  local: string;
  picture: string;
  given_name: string;
  family_name: string;
  email_verified: string;
}

interface IAuthContext {
  currentUserFirstName: string | undefined;
  idToken: string | undefined;
  processToken: (token: string) => void;
  logout: () => void;
  loggedIn: boolean;
  email: string | undefined;
}

export const AuthContext = createContext<IAuthContext>({
  currentUserFirstName: undefined,
  email: undefined,
  processToken: (token: string) => {},
  idToken: undefined,
  loggedIn: false,
  logout: () => {},
});

interface Props {
  children: ReactNode;
}

export const AuthContextProvider = ({ children }: Props) => {
  const [authInfo, setAuthInfo] = useState<IGoogleEndPointResponse | undefined>(
    () => {
      const storedAuth = localStorage.getItem("auth");
      if (storedAuth != null) {
        try {
          const temp: IGoogleEndPointResponse = JSON.parse(storedAuth);
          return temp;
        } catch (e) {
          return undefined;
        }
      }
      return undefined;
    }
  );
  const navigate = useNavigate();

  const logout = () => {
    googleLogout();
    setAuthInfo(undefined);
    localStorage.removeItem("auth");
    navigate("/");
  };


  const [idToken, setIdToken] = useState<string|undefined>(() => {
    const cachedIdToken = localStorage.getItem('taskwork.auth.idToken')

    if(cachedIdToken != null){
      return cachedIdToken
    }

    return undefined
  });
  const processToken = (token: string) => {
    setIdToken(token);

    const authInfo: IGoogleEndPointResponse = jwtDecode(token);
    localStorage.setItem('taskwork.auth.idToken',token)
    setAuthInfo(authInfo);
  };

  useEffect(() => {
    if (authInfo == null) {
      return;
    }
    try {
      


      localStorage.setItem("auth", JSON.stringify(authInfo));
    } catch (e) {}
  }, [authInfo]);

 
  const loggedIn = useMemo(() => {
    return (
      authInfo != null &&
      idToken != null &&
      authInfo.given_name != null &&
      ((Number.parseInt(authInfo.exp) + 86400) * 1000) > Date.now()
    );
  }, [authInfo,idToken]);
  
    useGoogleOneTapLogin({
        onSuccess: credentialResponse => {
            processToken(credentialResponse.credential!)
            //console.log(credentialResponse)

        },
        onError: () => {
          console.log('Login Failed');
        },
        auto_select: true,
        disabled:loggedIn || window.location.pathname === '/'
      });


 

  return (
    <AuthContext.Provider
      value={{
        logout,
        idToken,
        loggedIn,
        processToken,
        email: authInfo?.email,
        currentUserFirstName: authInfo?.given_name,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
