import { useLocation } from "react-router-dom";
import { AppLayout } from "@cloudscape-design/components";
import SideNav from "./SideNav";
import { TWRouter } from "../../routing/TWRouter";
import useFeatureFlags, { FeatureFlags } from "../../hooks/useFeatureFlags";
import Navbar from "./homeNav/TopNavHome";
import { TopNav } from "./TopNav";


export default function TWAppLayout() {
  const location = useLocation();
  const { isFeatureEnabled } = useFeatureFlags();

  if (location.pathname.startsWith("/login")) {
    return <TWRouter />;
  }
  if (
    !location.pathname.startsWith("/tools") ||
    !isFeatureEnabled(FeatureFlags.BETA_ACCESS)
  ) {
    return (
      <>
        <Navbar />
        <TWRouter />
      </>
    );
  }

  return (
    <>
      <TopNav />
      <AppLayout
      contentHeader={<></>}
        navigation={<SideNav />}
        navigationHide={!location.pathname.startsWith("/tools")}
        toolsHide={!location.pathname.startsWith("/tools")}
        content={<TWRouter />}
      />
    </>
  );
}
