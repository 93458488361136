import { Route, Routes } from "react-router-dom";
import { Home } from "../components/home/Home";
import ToolsHome  from "../components/tools/ToolsHome";
import AudioTranscribe from "../components/tools/audio/AudioTranscribe";
import AudioSummarize from "../components/tools/audio/AudioSummarize";
import YouTubeTranscribe from "../components/tools/youtube/YouTubeTranscribe";
import YouTubeSummarize from "../components/tools/youtube/YouTubeSummarize";
import SocialMediaPost from "../components/tools/content/SocialMediaPost";
import BlogArticle from "../components/tools/content/BlogArticle";
import ToolsRetrieve from "../components/tools/result/ToolsRetrieve";
import { ProtectedRoute } from "./ProtectedRoute";
import TWLogin from "../components/layout/Login";
import BetaAccessThanks from "../components/betaAccess/BetaAccessThanks";
import MyJobs from "../components/tools/result/MyJobs";
import WebpageSummarize from "../components/tools/web/WebpageSummarize";
import Transcribe from "../components/tools/text/Transcribe";
import Summarize from "../components/tools/text/Summarize";
import CreateJobWizard from "../components/forms/wizards/CreateJobWizard";
import SocialMediaScheduleWizard from "../components/forms/wizards/SocialMediaScheduleWizard";

export const TWRouter = () => {


  return (
   
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<TWLogin />} />
        
        <Route path="/beta" element={<BetaAccessThanks />} />
        

        {/* TOOLS */}
        <Route path="/tools" element={<ProtectedRoute><ToolsHome /></ProtectedRoute>} />

        
        <Route path="/tools/wizard" element={<ProtectedRoute><CreateJobWizard /></ProtectedRoute>} />
        <Route path="/tools/advanced/wizard" element={<ProtectedRoute><CreateJobWizard /></ProtectedRoute>} />
        <Route path="/tools/scheduler/wizard" element={<ProtectedRoute><SocialMediaScheduleWizard /></ProtectedRoute>} />
        
        {/* TEXT */}
        <Route path="/tools/text/transcribe" element={<ProtectedRoute><Transcribe /></ProtectedRoute>} />
        <Route path="/tools/text/summarize" element={<ProtectedRoute><Summarize /></ProtectedRoute>} />

        {/* TOOLS ---- AUDIO */} 
        <Route path="/tools/audio/transcribe" element={<ProtectedRoute><AudioTranscribe /></ProtectedRoute>} />
        <Route path="/tools/audio/summarize" element={<ProtectedRoute><AudioSummarize /></ProtectedRoute>} />

        {/* TOOLS ---- YOUTUBE */} 
        <Route path="/tools/youtube/transcribe" element={<ProtectedRoute><YouTubeTranscribe /></ProtectedRoute>} />
        <Route path="/tools/youtube/summarize" element={<ProtectedRoute><YouTubeSummarize /></ProtectedRoute>} />

        {/* TOOLS ---- CONTENT */} 
        <Route path="/tools/content/social-media-post" element={<ProtectedRoute><SocialMediaPost /></ProtectedRoute>} />
        <Route path="/tools/content/webpage-summarize" element={<ProtectedRoute><WebpageSummarize /></ProtectedRoute>} />
        <Route path="/tools/content/blog-article" element={<ProtectedRoute><BlogArticle /></ProtectedRoute>} />
        <Route path="/tools/jobs/:requestId" element={<ProtectedRoute><ToolsRetrieve /></ProtectedRoute>} />
        <Route path="/tools/jobs" element={<ProtectedRoute><MyJobs /></ProtectedRoute>} />


      </Routes>

  );
};
