import { SpaceBetween } from "@cloudscape-design/components";
import GeneratedFormField from "./GeneratedFormField";
import { FormFieldItem } from "./formGeneratorConstants";

interface GeneratedFormSectionProps{
    formSectionItems: FormFieldItem[]
    onChange?: any,
    openTools?: any,
  }
  export const GeneratedFormSection = (
  {formSectionItems,onChange,openTools}:GeneratedFormSectionProps
  ) => {
  
    return (
     
      <SpaceBetween size="m">
        {formSectionItems.map((field: FormFieldItem, idx: number) => (
          <GeneratedFormField
            key={idx}
            field={field}
          />
        ))}
      </SpaceBetween>
    );
  };