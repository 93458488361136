import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

export enum FeatureFlags{
    BETA_ACCESS= "BETA_ACCESS",
    GENERATION_DESTINATION = 'GENERATION_DESTINATION'
}


const betaAccessEmails = new Set(['cboseak@gmail.com', 'joshua.thomas.kelly.15@gmail.com'])
const useFeatureFlags = () => {
    const { email } = useContext(AuthContext)



    const isFeatureEnabled = (flag : FeatureFlags) => {

        switch(flag){
            case FeatureFlags.BETA_ACCESS:
                return betaAccessEmails.has(email ?? "") 
        }
        return false

    }
 

  return { isFeatureEnabled };
};

export default useFeatureFlags;
