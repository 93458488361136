import { GeneratedFormSection } from "./GeneratedFormSection";
import { FormStep } from "./formGeneratorConstants";

interface Props {
  formStepDefinition: FormStep;
}
const WizardStepGenerator = ({ formStepDefinition }: Props) => {


  return (
    <>
   
        <GeneratedFormSection formSectionItems={formStepDefinition.items} />
     
    </>
  );
};

export default WizardStepGenerator;
