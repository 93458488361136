import { RadioGroupProps } from "@cloudscape-design/components";
import {
  OptionDefinition,
  OptionGroup,
} from "@cloudscape-design/components/internal/components/option/interfaces";
import { ReactNode } from "react";

export interface WizardDefintion {
  [key: string]: FormStep;
}
export interface FormStep {
  type: "section" | "form" | "review";
  conditionals?: Conditional[];
  title?: string;
  text?: string;
  description?: string;
  position?: number;
  items: FormFieldItem[];
  required?: boolean;
  uniqueId?: string;

}

export const NON_VALIDATION_FIELD_TYPES = new Set(['divider','markdown'])
export interface Conditional {
  parentId: string;
  condition: any;
  parentIdValueProperty?: string;
  conditionFn?: (val: any) => boolean;
  type: "required" | "show";
}
export interface FieldLink {
  text: string;
  href: string;
}

export interface SelectOptionItem extends OptionDefinition {
  tag?: string;
  default?: boolean;
  serviceValue?: string
}
export interface SelectOptionGroup extends OptionGroup {
  options: ReadonlyArray<SelectOptionItem>;
}

export interface FormFieldItem {
  type:
    | "input"
    | "select"
    | "checkbox"
    | "fileUpload"
    | "input"
    | "textarea"
    | "radio"
    | "number"
    | "email"
    | "url"
    | "markdown"
    | "multiselect"
    | "divider"
    | "step"
    | "toggle";
  id?: string;
  serviceKey?: string;
  selectOptionVariant?: "label" | "option";
  featureFlagName?: string,
  label?: string;
  uniqueId: string;
  description?: string;
  placeholder?: string;
  text?: string;
  disabled?: boolean,
  conditionals?: Conditional[];
  tags?: string[];
  regex?: string;
  links?: FieldLink[];
  validations?: ValidationItem[];
  required?: boolean;
  parentId?: string;
  formatInstructions?: string;
  defaultValue?: any;
  transformer?: (x: any) => any;
  selectOptions?: (SelectOptionItem | SelectOptionGroup)[];
  radioOptions?: RadioGroupProps.RadioButtonDefinition[];
  tooltip?: Tooltip;
  nonValueField?: boolean;
  formStepDefinition?: FormStep
}
export interface ValidationItem {
  errorText: string;
  validationFunction: (val: any) => boolean;
}
export interface Tooltip {
  title: string;
  content: ReactNode;
}


