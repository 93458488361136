import { Box } from "@cloudscape-design/components";
import { useMemo } from "react";
import { toTitleCase, truncateString } from "../../../common/textUtils";
import ReactTimeago from "react-timeago";

const FIELDS_TO_SKIP_TITLE_CASE = new Set([
  "referenceImage",
  "youtubeUrl",
  "mp3Url",
  "url",
  "id",
  "estimation",
  "text",
  "startTime",
  "start_time",
]);

export const EPOCH_FIELDS = new Set(["createdTime"]);

const customFields: any = {
  estimation: (item: number) => {
    return `${(Math.round(item * 100) / 100).toFixed(2)} seconds`;
  },
  createdTime: (item: number) => <ReactTimeago date={new Date(item)} />,
};
interface Props {
  itemKey: any;
  value: any;
}
export const ResultInfoKeyValueItem = ({ itemKey, value }: Props) => {
  const truncatedValue = useMemo(
    () => truncateString(value.toString(), 100),
    [value]
  );

  const valueNode = useMemo(() => {
    if (!FIELDS_TO_SKIP_TITLE_CASE.has(itemKey)) {
      return toTitleCase(truncatedValue);
    } else if (customFields[itemKey] != null) {
      return customFields[itemKey](value);
    }
    return truncatedValue;
  }, [truncatedValue, itemKey]);

  if (value == null) {
    return null;
  }

  return (
    <Box>
      <Box variant="awsui-key-label">{toTitleCase(itemKey)}</Box>
      <Box>{valueNode}</Box>
    </Box>
  );
};

export default ResultInfoKeyValueItem;
