import {
    Box,
    Container,
    Header,
    SpaceBetween,
  } from "@cloudscape-design/components";
  import { FormStep } from "./formGeneratorConstants";

  import WizardFooterButtons from "./WizardFooterButtons";
  import WizardStepBody from "./WizardStepBody";
  
  interface Props {
    currentStep: FormStep;
    stepsArr: FormStep[];
    alignButtons?: "left" | "right"
  }
  
  const WizardStepContainer = ({ currentStep, stepsArr }: Props) => {


    return (
      <>
            <Container 
              variant="stacked"
              header={
                <Header description={currentStep.description || ""}>
                  {currentStep.title || ""}
                </Header>
              }
            >
              <WizardStepBody currentStep={currentStep} stepsArr={stepsArr} />
            </Container>
            <Container 
            variant="stacked"
            >
              <WizardFooterButtons
                stepsArr={stepsArr}
                currentStep={currentStep}
              />
            </Container>
      </>
    );
  };
  
  export default WizardStepContainer;
  