import {
  Box,
  ColumnLayout,
  Container,
  ContentLayout,
  Header,
  ProgressBar,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";
import { useParams } from "react-router";
import useApiRequest from "../../../hooks/useApiRequest";
import { useEffect, useMemo, useState } from "react";
import { getApiDomain } from "../../../config/envConfig";
import ResultsContainer from "./ResultsContainer";
import ResultInfoKeyValueItem from "./ResultInfoKeyValueItem";

const FIELDS_TO_HIDE = new Set([
  "result_data",
  "user",
  "final_result",
  "result",
]);

export const ToolsRetrieve = () => {
  let { requestId } = useParams();
  const { apiRequest } = useApiRequest();
  const [loading, setLoading] = useState(false);
  const [jobInfo, setJobInfo] = useState<any>();
  useEffect(() => {
    setLoading(true);
    apiRequest(`${getApiDomain()}/jobs/${requestId}`, "GET")
      .then((job: any) => {
        setJobInfo(job);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [requestId]);

  const progressInfo = useMemo(() => {
    if (jobInfo?.step_status == null) {
      return { percent: 0, stepProgress: "n/a" };
    }
    const totalNumSteps = Object.values(jobInfo?.step_status ?? {}).length;
    const stepStatusMap: any = {};

    Object.entries(jobInfo?.step_status ?? {}).forEach((x: any[]) => {
      const val = x[1];
      if (stepStatusMap[val] == null) {
        stepStatusMap[val] = 0;
      }
      stepStatusMap[val]++;
    });
    return {
      percent: ((stepStatusMap["success"] ?? 0) / totalNumSteps) * 100,
      stepProgress: `Completed ${
        stepStatusMap["success"] ?? 0
      } / ${totalNumSteps} steps`,
    };
  }, [jobInfo]);

  const filteredJobInfoParts: [string, any][] = useMemo(() => {
    if (jobInfo == null) {
      return [];
    }

    return Object.entries(jobInfo)
      .filter(
        (entry: any) =>
          typeof entry[1] !== "object" &&
          !FIELDS_TO_HIDE.has(entry[0])&&
          entry[1].toString().length > 0
      )
      .map((x: any) => x);
  }, [jobInfo]);

  return (
    <ContentLayout header={<Header>Retrieve Results</Header>}>
      <SpaceBetween size="m">
        <Container>
          {loading ? (
            <Box textAlign="center">
              
              <Spinner size="large" />
              </Box>
          ) : (
            <ColumnLayout variant="text-grid" columns={4}>
              {filteredJobInfoParts.map((entry: any) => {
                const key = entry[0];
                const value = entry[1];
                return (
                  <ResultInfoKeyValueItem
                    key={key}
                    itemKey={key}
                    value={value}
                  />
                );
              })}
              {jobInfo?.step_status && (
                <Box key="progress">
                  <Box variant="awsui-key-label">Progress</Box>
                  <ProgressBar
                    description={progressInfo.stepProgress}
                    value={progressInfo.percent}
                  />
                </Box>
              )}
            </ColumnLayout>
          )}
        </Container>

        <ResultsContainer
          finalResultIds={jobInfo?.result_steps}
        />
      </SpaceBetween>
    </ContentLayout>
  );
};

export default ToolsRetrieve;
