import { Box, ColumnLayout, Header } from "@cloudscape-design/components";

import ReviewStepItem from "./ReviewStepItem";
import { FormFieldItem, FormStep } from "../formGeneratorConstants";

interface Props {
  step: FormStep;
  stepItems: FormFieldItem[]
}

const ReviewStepSection = ({ step, stepItems }: Props) => {

    return (<>  {step.title && step.text && (
        <Header description={step.description} variant="h3">
          {step.title ?? step.text}
        </Header>
      )}
      <ColumnLayout columns={2}>
        {stepItems.map((item, itemIdx) => (
          <ReviewStepItem key={itemIdx} item={item} />
        ))}
      </ColumnLayout></>)

};

export default ReviewStepSection;
