import { Conditional, FormStep } from "../../wizardGenerator/formGeneratorConstants";



const getInputSourceStep = (conditionals? : Conditional[]): FormStep => { return {
  type: "section",
  text: "Source",
  conditionals: conditionals,
  items: [
    {
      type: "select",
      label: "Source",
      uniqueId: "input-based-content-source-select",
      defaultValue:"input-based-source-raw-text",
      description: "Where should we get the inspiration for your content?",
      required: true,
      selectOptions: [
        {
          label: "Youtube Video URL",
          description:
            "Using a Youtube video URL, we will use the video text and speech contents as a content source.",
          value: "input-based-source-youtube-video-url",
        },
        {
          label: "MP3 URL",
          description:
            "Using an MP3 URL, such as a podcast or audio recording, we will use the speech contents as a content source.",
          value: "input-based-source-mp3-url",
        },
        {
          label: "Raw Text Input",
          description: "Input raw text for us to use to generate content",
          value: "input-based-source-raw-text",
        },
        {
          label: "AI Generated Topics",
          value: "input-based-source-ai-topic-generation",
          description:
            "Give a base topic and we will use that to generate content in related, similar, or adjacent topics.",
        },
      ],
      transformer: (x: any) => x,
    },
    {
        type: "url",
        label: "Youtube Video URL",
        uniqueId: "input-based-source-youtube-video-url-input",
        placeholder: "https://youtube.com/1234567890",
        conditionals: [
          {
            parentId: "input-based-content-source-select",
            condition: "input-based-source-youtube-video-url",
            parentIdValueProperty: "value",
            type: "required",
          },
          {
            parentId: "input-based-content-source-select",
            parentIdValueProperty: "value",
            condition: "input-based-source-youtube-video-url",
            type: "show",
          },
        ],

        transformer: (x: any) => x,
      },
      {
          type: "url",
          label: "MP3 URL",
          uniqueId: "input-based-source-mp3-url-input",
          placeholder: "https://example.com/example.mp3",
          conditionals: [
            {
              parentId: "input-based-content-source-select",
              condition: "input-based-source-mp3-url",
              parentIdValueProperty: "value",
              type: "required",
            },
            {
              parentId: "input-based-content-source-select",
              parentIdValueProperty: "value",
              condition: "input-based-source-mp3-url",
              type: "show",
            },
          ],
  
          transformer: (x: any) => x,
        },
        {
            type: "textarea",
            label: "Raw Text",
            uniqueId: "input-based-source-raw-text-input",
            conditionals: [
              {
                parentId: "input-based-content-source-select",
                condition: "input-based-source-raw-text",
                parentIdValueProperty: "value",
                type: "required",
              },
              {
                parentId: "input-based-content-source-select",
                parentIdValueProperty: "value",
                condition: "input-based-source-raw-text",
                type: "show",
              },
            ],
    
            transformer: (x: any) => x,
          },
          {
            type: "input",
            label: "Starting Phrase or Word",
            uniqueId: "input-based-source-ai-topic-generation-input",
            description: "Enter a word or phrase as a starting point for your content. For example, if you provide the word 'car', we will create content about car brands, engines, racing, etc.",
            placeholder: "car",
            conditionals: [
              {
                parentId: "input-based-content-source-select",
                condition: "input-based-source-ai-topic-generation",
                parentIdValueProperty: "value",
                type: "required",
              },
              {
                parentId: "input-based-content-source-select",
                parentIdValueProperty: "value",
                condition: "input-based-source-ai-topic-generation",
                type: "show",
              },
            ],
    
            transformer: (x: any) => x,
          },
  ],
}};


export const inputSourceStepWithConditionals = getInputSourceStep([
  {
    parentId: "content-frequency-type-select",
    parentIdValueProperty: "tag",
    condition: "input-based",
    type: "required",
  },
  {
    parentId: "content-frequency-type-select",
    parentIdValueProperty: "tag",
    condition: "input-based",
    type: "show",
  },
])
export const inputSourceStep =  getInputSourceStep();

//
