import { Box, FormField, SpaceBetween } from "@cloudscape-design/components";
import {
  getErrorTexts,
  getUniqueIdForField,
  isFieldOrStepRequired,
  showFieldOrStep,
} from "./formGeneratorUtils";
import { useContext, useMemo } from "react";
import { FormContext } from "./FormContext";
import GeneratedField from "./GeneratedField";
import { FormFieldItem } from "./formGeneratorConstants";
import * as twui from '@cloudscape-design/design-tokens';
import styled from 'styled-components';

const RequiredFlagSpan = styled.span`
  color: ${twui.colorTextStatusError}
`
interface GeneratedFormFieldProps {
  field: FormFieldItem;
  openToolsFn?: any;
}

const stretchInputTypes = new Set<string>([])
const GeneratedFormField = ({
  field,
  openToolsFn,
}: GeneratedFormFieldProps) => {
  const { rawFormValues, getFieldOnChange, formErrors } = useContext(FormContext);
  const isRequired = useMemo(
    () => isFieldOrStepRequired(field, rawFormValues),
    [field, rawFormValues]
  );
  if (!showFieldOrStep(field, rawFormValues)) {
    return null;
  }
  const uniqueId = getUniqueIdForField(field);


  return (
    <div key={uniqueId}>
      <FormField
      stretch={stretchInputTypes.has(field.type)}
        label={
          field.label != null && (
            <>
              <span>{field.label}</span>
              {isRequired && <><RequiredFlagSpan>*</RequiredFlagSpan></>}
            </>
          )
        }
        errorText={(() => {
          const errorTexts = getErrorTexts(field,formErrors)
          if(errorTexts.length === 0){
            return null
          }
          return <>{errorTexts?.map((x,i) => <Box key={i} color="text-status-error">{x}</Box>)}</>
        })()}
        description={field.description}
      >
        
        <GeneratedField field={field} onChangeFn={getFieldOnChange(field)} />

        <SpaceBetween size="s">
          {field.links != null &&
            field.links.map((x: any) => (
              <a
                target="_blank"
                rel="noopener noreferrer"
                key={uniqueId}
                href={x.href}
              >
                {x.text}
              </a>
            ))}
        </SpaceBetween>
      </FormField>
    </div>
  );
};
export default GeneratedFormField;
