import styled from "styled-components";
import * as twui from "@cloudscape-design/design-tokens";
import React, { ReactNode } from "react";
import { SpaceBetween, SpaceBetweenProps } from "@cloudscape-design/components";

const StyledDivider = styled.hr`
  border: 1px solid ${twui.colorBorderDividerDefault} !important;
  margin: ${twui.spaceScaledXs} !important;
`;

interface Props {
  children: ReactNode;
  size?: SpaceBetweenProps.Size;
  direction?: SpaceBetweenProps.Direction;
  alignItems?: SpaceBetweenProps.AlignItems;
}

const DividerBetween = ({
  children,
  size = "xxxs",
  direction,
  alignItems,
}: Props) => {
  return (
    <SpaceBetween size={size} direction={direction} alignItems={alignItems}>
      {React.Children.map(children, (child, i) => {
          //console.log(child?.valueOf())
        if(child == null){
          return null
        }
        return (
          <React.Fragment key={i}>
            {child && i > 0 && <StyledDivider />}
            {child}
          </React.Fragment>
        );
      })}
    </SpaceBetween>
  );
};

export default DividerBetween;
