import {
    ContentLayout,
    Header,
  } from "@cloudscape-design/components";
  import SingleFieldInputForm from "../common/SingleFieldInputForm";
  import useApiRequest from "../../../hooks/useApiRequest";
  import { getApiDomain } from "../../../config/envConfig";
  import { useNavigate } from "react-router-dom";
  
  export const WebpageSummarize = () => {
    const {apiRequest} = useApiRequest()
    const navigate = useNavigate()
    const submit = (val: string) => {
      apiRequest(`${getApiDomain()}/jobs`,'POST',{ url: val, type: "WebpageSummarize"})
      .then((resp) => {
        navigate(`/tools/jobs/${resp.id}`)
      })
      console.log(val)
    };
    return (
      <ContentLayout header={<Header>Webpage Summarize</Header>}>
        <SingleFieldInputForm onSubmit={submit} label="Webpage Url" />
      </ContentLayout>
    );
  };
  
  export default WebpageSummarize;
  