import { FormStep } from "../../wizardGenerator/formGeneratorConstants"

const frequencyStep : FormStep= {
    type: "section",
    text: "Frequency",
    required: true,
    items: [ {
        type: "select",
        label: "Run Frequency",
        uniqueId: "content-frequency-type-select",
        description: "When should we generate content?",
        defaultValue: "content-frequency-type-immediate",
        required: true,
        selectOptions: [
            {
                label: "Immediate",
                description:
                  "Generate content right now",
                value: "content-frequency-type-immediate",
                tag: "input-based"
              },

          {
            label: "Scheduled",
            description:
              "Schedule content creation for later. This may be recurring or a scheduled single run",
              value: "content-frequency-type-scheduled",
              tag: "input-based"
          },
          {
            label: "Event Driven",
            description:
              "Generate content based on events, such as an rss feed, webhooks, or social media posts.",
              value: "content-frequency-type-event",
              tag: "event-based"
          },

        ],
        transformer: (x: any) => x,
      },
      {
        type: "select",
        label: "Schedule Type",
        uniqueId: "content-frequency-schedule-type-select",
        description: "How often should we generate content?",
        required: true,
        selectOptions: [
            {
                label: "Once",
                description:
                  "Generate content once at a schedule date and time",
                value: "content-frequency-schedule-once",
              },

          {
            label: "Recurring",
            description:
            "Generate content on a recurring basis with a specific frequency.",
              value: "content-frequency-schedule-recurring",
              tag: "frequency-type",
          },


        ],
        conditionals: [
            {
              parentId: "content-frequency-type-select",
              parentIdValueProperty: "value",
              condition: "content-frequency-type-scheduled",
              type: "required",
            },
            {
              parentId: "content-frequency-type-select",
              parentIdValueProperty: "value",
              condition: "content-frequency-type-scheduled",
              type: "show",
            },
          ],
        transformer: (x: any) => x,
      }],
  }

  export default frequencyStep