import {
  Box,
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormContext, ValidateStepResult } from "./FormContext";
import { useContext, useEffect, useMemo, useState } from "react";
import WizardStepGenerator from "./WizardStepGenerator";
import { FormStep, WizardDefintion } from "./formGeneratorConstants";
import {
  clearErrorsForNotVisibleFields,
  isStepValid,
  showFieldOrStep,
} from "./formGeneratorUtils";
import Stepper from "@mui/material/Stepper";
import { Orientation, Step, StepContent, StepLabel } from "@mui/material";
import RightAlignedContent from "../../layout/RightAlignedContent";
import ReviewStep from "./review/ReviewStep";
import WizardFooterButtons from "./WizardFooterButtons";
import WizardStepBody from "./WizardStepBody";
import styled from "styled-components";
import * as twui from "@cloudscape-design/design-tokens";
import WizardStepContainer from "./WizardStepContainer";

interface Props {
  currentStep: FormStep;
  stepsArr: FormStep[];
  orientation?: Orientation;
  alternativeLabel?: boolean;
}

const ClickableDiv = styled.div<{ clickable: boolean }>`
  :hover {
    cursor: ${(props) => (props.clickable ? "pointer" : "inherit")};
    background: ${(props) =>
      props.clickable ? twui.colorBackgroundButtonNormalHover : "inherit"};
  }
`;
const WizardStepperControl = ({
  stepsArr,
  orientation = "horizontal",
  alternativeLabel,
}: Props) => {
    const {activeStepIndex,setActiveStepIndex} = useContext(FormContext)
  const [seenSteps, setSeenSteps] = useState<Set<number>>(new Set());
  useEffect(() => {
    if (!seenSteps.has(activeStepIndex)) {
      setSeenSteps((prev) => prev.add(activeStepIndex));
    }
  }, [activeStepIndex]);

  return (
    <Stepper
      activeStep={activeStepIndex}
      orientation={orientation}
      alternativeLabel={alternativeLabel}
    >
      {stepsArr.map((step, i) => (
        <Step key={step.uniqueId ?? i}>
          <ClickableDiv
            clickable={i!== activeStepIndex && seenSteps.has(i) }
            onClick={() => {
       
              if (!seenSteps.has(i)) {
                return;
              }
              setActiveStepIndex(i);
            }}
          >
            <StepLabel>{step.title || step.text || ""}</StepLabel>
          </ClickableDiv>
        </Step>
      ))}
    </Stepper>
  );
};

export default WizardStepperControl;
