import {
  FormField,
  Select,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useState } from "react";
import { toTitleCase } from "../../../common/textUtils";

export enum ImageStyleTypes {
  CARTOON = "CARTOON",
  CHILDRENS_CARTOON = "CHILDRENS_CARTOON",
  SIMPLE_CARTOON = "SIMPLE_CARTOON",
  MINIMALIST_CARTOON = "MINIMALIST_CARTOON",
  DARK_MOODY = "DARK_MOODY",
  HAZY_35 = "HAZY_35",
  DETAILED_MATTED = "DETAILED_MATTED",
  ANIME = "ANIME",
  PUPPET = "PUPPET",
  CLAYMATION = "CLAYMATION",
  CARTOON_VIBRANT = "CARTOON_VIBRANT",
  HYPER_REALISTIC_CYBERPUNK = "HYPER_REALISTIC_CYBERPUNK",
  YOUTUBE_THUMBNAIL = "YOUTUBE_THUMBNAIL",
  HYPER_REALISTIC_VIBRANT = "HYPER_REALISTIC_VIBRANT",
}
interface Props {
  onChange: (val?: string) => void;
  isLoading?: boolean;
}


const options = [{ label: "None (default)", value: "" },...Object.values(ImageStyleTypes).map(x => ({ label: toTitleCase(x), value: x }) )]

const ImageStyleSelector = ({ onChange, isLoading = false }: Props) => {
  const [selectedOption, setSelectedOption] = useState<OptionDefinition | null>(
    () => {
      onChange(options[0].value);
      return options[0];
    }
  );

  return (
    <FormField
      label="Image style"
      description="The style for the generated image"
    >
      <Select
        selectedOption={selectedOption}
        disabled={isLoading}
        onChange={({ detail }) => {
          setSelectedOption(detail.selectedOption);
          onChange(detail.selectedOption.value);
        }}
        options={options}
      />
    </FormField>
  );
};

export default ImageStyleSelector;
