import { useContext, useEffect, useMemo, useState } from "react";
import {
  FormContext,
  StringKeyArrayValue,
  ValidateStepResult,
} from "./FormContext";
import { FormStep, WizardDefintion } from "./formGeneratorConstants";
import { showFieldOrStep } from "./formGeneratorUtils";
import {
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import WizardStepGenerator from "./WizardStepGenerator";
import RightAlignedContent from "../../layout/RightAlignedContent";

interface Props {
  wizardDefinition: WizardDefintion;
}

const TwForm = ({ wizardDefinition }: Props) => {
  const { rawFormValues, validateAllSteps } =
    useContext(FormContext);
  const steps = useMemo(
    () =>
      Object.values(wizardDefinition).filter((step) =>
        showFieldOrStep(step, rawFormValues)
      ),
    [wizardDefinition, rawFormValues]
  );



  const onSubmit = () => {
    validateAllSteps(steps).then(
      (res: ValidateStepResult[]) => {
        const isValid = res.every(x => x.isValid)
        if(isValid){
          console.log("valid - submitting")
        }else{
          console.log("not valid")
        }
      },
      function (err) {
        // error occurred
      }
    );

  };

  return (
    <>
      {steps.map((step, i) => {
        return (
          <Container
            key={step.uniqueId || i}
            variant="stacked"
            header={
              <Header description={step.description || ""}>
                {step.title || ""}
              </Header>
            }
          >
            <WizardStepGenerator formStepDefinition={step} />
          </Container>
        );
      })}
      <Container variant="stacked">
        <RightAlignedContent>
          <SpaceBetween size="xs" direction="horizontal">
            <Button>Cancel</Button>

            <Button variant="primary" onClick={onSubmit}>
              Submit
            </Button>
          </SpaceBetween>
        </RightAlignedContent>
      </Container>
    </>
  );
};

export default TwForm;
