import {
  Container,
} from "@cloudscape-design/components";
import { FormContext, ValidateStepResult } from "./FormContext";
import { useContext, useEffect, useMemo, useState } from "react";
import {  WizardDefintion } from "./formGeneratorConstants";
import {
  clearErrorsForNotVisibleFields,
  showFieldOrStep,
} from "./formGeneratorUtils";
import WizardStepContainer from "./WizardStepContainer";
import WizardStepperControl from "./WizardStepperControl";

interface Props {
  wizardDefinition: WizardDefintion;
  showSteps?: boolean;
}

const TwWizard = ({ wizardDefinition, showSteps = true }: Props) => {

  const {
    rawFormValues,
    setFormErrors,
    formErrors,
    activeStepIndex,
    setActiveStepIndex
  } = useContext(FormContext);

  const stepsArr = useMemo(() => {
    const steps = Object.values(wizardDefinition).filter((step) =>
      showFieldOrStep(step, rawFormValues)
    );
    steps.push({ type: "review", text: "Review", items: [] });
    return steps;
  }, [wizardDefinition, rawFormValues]);

  const currentStep = useMemo(
    () => stepsArr[activeStepIndex],
    [stepsArr, activeStepIndex]
  );

  useEffect(() => {
    const objWithErrorsCleared = clearErrorsForNotVisibleFields(
      formErrors,
      Object.values(wizardDefinition),
      rawFormValues
    );
    setFormErrors((prev: any) => ({ ...prev, ...objWithErrorsCleared }));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepsArr]);


  return (
    <>
      {showSteps && (
        <Container variant="stacked">
          <WizardStepperControl
            currentStep={currentStep}
            stepsArr={stepsArr}
            alternativeLabel
          />
        </Container>
      )}
  
        <WizardStepContainer stepsArr={stepsArr}  currentStep={currentStep}  />
    </>
  );
};

export default TwWizard;
