import {  ContentLayout, Header } from "@cloudscape-design/components";
import SingleFieldInputForm from "../common/SingleFieldInputForm";
import useApiRequest from "../../../hooks/useApiRequest";
import { useNavigate } from "react-router-dom";
import { getApiDomain } from "../../../config/envConfig";
import { useState } from "react";

 const YouTubeTranscribe = () => {
  const {apiRequest} = useApiRequest()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const submit = (val: string) => {
    setIsLoading(true)
    apiRequest(`${getApiDomain()}/jobs`,'POST',{ youtubeUrl: val, type: "YouTubeTranscribe"})
    .then((resp) => {
      navigate(`/tools/jobs/${resp.id}`)
    })
    .finally(() => {
      setIsLoading(false)
    })
    console.log(val)
  };
  return (
    <ContentLayout header={<Header>YouTube Video Transcribe</Header>}>
      <SingleFieldInputForm isLoading={isLoading} onSubmit={submit} label="YouTube Video Url" />
    </ContentLayout>
  );
};

export default YouTubeTranscribe
