import {
  ColumnLayout,
  Container,
  Header,
  Link,
  Spinner,
} from "@cloudscape-design/components";
import { camelCaseToWords } from "../../../common/textUtils";
import React, { useEffect, useMemo, useState } from "react";
import useApiRequest from "../../../hooks/useApiRequest";
import { getApiDomain } from "../../../config/envConfig";

interface Props {
  finalResultIds?: string[];
}
export const ResultsContainer = ({ finalResultIds }: Props) => {
  const [results, setResults] = useState<any[]>([]);
  const { apiRequest } = useApiRequest();
  useEffect(() => {
    const promises: any[] = [];
    finalResultIds?.forEach((x) => {
      promises.push(apiRequest(`${getApiDomain()}/step/${x}`, "GET"));
    });
    Promise.all(promises).then((res) => {
      setResults(res);
    });
  }, [finalResultIds]);

  if (finalResultIds == null) {
    return null;
  }
  if(finalResultIds.length > 0 && results.length !== finalResultIds.length){
    return  <Container
    variant="stacked"><Spinner size="large" /></Container>
  }
  return (
    <>
      {results.map((entry: any, i) => {
        const value = entry?.result;
        const key = entry?.step_name ?? ''
        //console.log(entry)
        // console.log(value)
        // console.log(key)
        return (
          <Container
            header={<Header variant="h3">{camelCaseToWords(key)}</Header>}
            variant="stacked"
            key={key}
          >
            {Array.isArray(value) ? (
              <ColumnLayout columns={Math.min(value.length, 4)}>
                {value.map((x: string, idx) => (
                  <Link key={idx} target="_blank" href={x}>
                    <img src={x} alt="" />
                  </Link>
                ))}
              </ColumnLayout>
            ) : (
              value == null? <Spinner size="normal" /> : value
            )}
          </Container>
        );
      })}
    </>
  );
};

export default ResultsContainer;
