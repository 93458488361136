import { FormStep } from "../../wizardGenerator/formGeneratorConstants";
import { v4 as uuid } from "uuid";
const contentProcessingStep: FormStep = {
  type: "section",
  text: "Content processing",
  description: "What content should we generate?",
  required: true,
  items: [
    {
      type: "select",
      label: "Type",
      uniqueId: "content-processing-type-select",
      description: "What type of content should we generate?",
      required: true,
      selectOptions: [
        {
          label: "Social Media Post",
          description:
            "A social media post tailored to the platform of your choosing.",
          value: "processing-type-social",
          tag: "text",
        },

        {
          label: "Video (Coming soon)",
          disabled: true,
          description: "Video based on the source content.",
          value: "processing-type-video",
          tag: "video",
        },
        {
          label: "Text",
          description:
            "Long form text such as blog posts, transcription, notes, and summaries.",
          value: "processing-type-long-form-text",
          tag: "text",
        },
      ],
      transformer: (x: any) => x,
    },
    {
      type: "toggle",
      uniqueId: "content-processing-advanced-mode-checkbox",
      text: "Advanced Options",
      serviceKey: "advancedOptions",
      required: false,
      description: "Customize your output",
    },
    {
      type: "select",
      label: "Social Media Type",
      uniqueId: "content-processing-social-type-select",
      description: "What style of social media post should we create?",
      required: true,
      serviceKey: "socialMediaTextType",
      conditionals: [
        {
          parentId: "content-processing-type-select",
          parentIdValueProperty: "value",
          condition: "processing-type-social",
          type: "required",
        },
        {
          parentId: "content-processing-type-select",
          parentIdValueProperty: "value",
          condition: "processing-type-social",
          type: "show",
        },
      ],
      selectOptions: [
        {
          label: "X/Twitter",
          description:
            "Limit to 280 characters, heavy emoji use, heavy hashtag use",
          value: "processing-social-twitter-post",
          serviceValue: "twitter",
          tag: "social",
        },
        {
          label: "Instagram",
          description:
            "Limit to 2,200 characters, light emoji use, light hashtag use, text that describe the attached photo",
          value: "processing-social-instagram-post",
          serviceValue: "instagram",
          tag: "social",
        },
        {
          label: "Facebook",
          description:
            "Longer length, light emoji use, light hashtag use, text that describes your content",
          serviceValue: "facebook",
          value: "processing-social-instagram-post",
          tag: "social",
        },
        {
          label: "Other",
          value: "processing-social-other",
          serviceValue: "other",
          description: "Customize character limit, emoji use, and hashtag use.",
          tag: "social",
        },
      ],
      transformer: (x: any) => x,
    },
    {
      type: "select",
      label: "Video Type",
      uniqueId: "content-processing-video-type-select",
      description: "What style of social media post should we create?",
      serviceKey: "videoType",
      required: true,
      conditionals: [
        {
          parentId: "content-processing-type-select",
          parentIdValueProperty: "value",
          condition: "processing-type-video",
          type: "required",
        },
        {
          parentId: "content-processing-type-select",
          parentIdValueProperty: "value",
          condition: "processing-type-video",
          type: "show",
        },
      ],
      selectOptions: [
        {
          label: "Short Form Video",
          description:
            "A short video for platforms like TikTok, Youtube Shorts, or Reels. ",
          value: "processing-video-tiktok",
          tag: "video",
        },
        {
          label: "Explainer Video",
          value: "processing-video-explainer",
          description:
            "A video that will give an overview and explain the high level concepts of your source content.",
          tag: "video",
        },
      ],
      transformer: (x: any) => x,
    },
    {
      type: "select",
      label: "Long Form Text Type",
      uniqueId: "content-processing-long-form-text-type-select",
      description: "What style of social media post should we create?",
      required: true,
      serviceKey: "longFormTextType",
      conditionals: [
        {
          parentId: "content-processing-type-select",
          parentIdValueProperty: "value",
          condition: "processing-type-long-form-text",
          type: "required",
        },
        {
          parentId: "content-processing-type-select",
          parentIdValueProperty: "value",
          condition: "processing-type-long-form-text",
          type: "show",
        },
      ],
      selectOptions: [
        {
          label: "Satirical Article",
          description:
            "Text about the subject in your source content that uses humor, irony, and exaggeration.",
          value: "processing-text-satire",
          tag: "text",
        },
        {
          label: "Tutorial/How To",
          value: "processing-text-tutorial",
          description:
            "Text with a how to guide on the content in the subject.",
          tag: "text",
        },
        {
          label: "Top 10 List",
          description:
            "A top 10 list of things about the subject of the content",
          value: "processing-text-top-10",
          tag: "text",
        },
        {
          label: "Review/Critique",
          description:
            "A review or critique of the subject in the source content",
          value: "processing-text-review",
          tag: "text",
        },
        {
          label: "Summarize",
          description: "Provide a short summary of the source content",
          value: "processing-text-summarize",
          tag: "text",
        },
        {
          label: "Notes",
          value: "processing-text-notes",
          description:
            "Create a bulleted outline with notes of the source content",
          tag: "text",
        },
        {
          label: "Transcribe",
          value: "processing-text-transcribe",
          description:
            "For video and audio source content, transcribe it into text.",
          tag: "text",
        },
        {
          label: "Other",
          description: "Customize output style, length, objective.",
          value: "processing-text-other",
          tag: "text",
        },
      ],
      transformer: (x: any) => x,
    },
    {
      type: "divider",
      uniqueId: uuid(),
      nonValueField: true,
    },
    {
      type: "number",
      label: "Character Limit",
      description:
        "The max number of characters for the generated content. Between 140 and 5,000. Actual length may vary and cannot be guaranteed.",
      uniqueId: "input-based-source-character-limit-input",
      serviceKey: "socialTextCharacterLevel",
      regex: "^[140-5000]$",
      conditionals: [
        {
          parentId: "content-processing-social-type-select",
          parentIdValueProperty: "value",
          condition: "processing-social-other",
          type: "show",
        },
      ],

      transformer: (x: any) => x,
    },
    {
      type: "select",
      label: "Emoji Level",
      serviceKey: "socialTextEmojiLevel",
      uniqueId: "content-processing-emoji-level",
      description: "What frequency of emojis should the generated content have",

      conditionals: [
        {
          parentId: "content-processing-social-type-select",
          parentIdValueProperty: "value",
          condition: "processing-social-other",
          type: "show",
        },
      ],
      selectOptions: [
        {
          label: "None",
          value: "content-processing-emoji-level-none",
        },
        {
          label: "Some",
          description: "At least a few emojis",
          value: "content-processing-emoji-level-none",
        },
        {
          label: "A lot",
          description: "Go crazy with emojis throughout the content",
          value: "content-processing-emoji-level-none",
        },
      ],
      transformer: (x: any) => x,
    },
    {
      type: "select",
      label: "Hashtag Level",
      uniqueId: "content-processing-hashtag-level",
      serviceKey: "socialTextHashtagLevel",
      description:
        "What frequency of hashtags should the generated content have",

      conditionals: [
        {
          parentId: "content-processing-social-type-select",
          parentIdValueProperty: "value",
          condition: "processing-social-other",
          type: "show",
        },
      ],
      selectOptions: [
        {
          label: "None",
          value: "content-processing-hashtag-level-none",
        },
        {
          label: "Some",
          description: "At least a few",
          value: "content-processing-hashtag-level-none",
        },
        {
          label: "A lot",
          description: "Go crazy with them throughout the content",
          value: "content-processing-hashtag-level-none",
        },
      ],
      transformer: (x: any) => x,
    },
    //
    {
      type: "select",
      label: "Content Writing Style",
      uniqueId: "content-processing-writing-style",
      defaultValue: "content-processing-writing-style-default",
      serviceKey: "textWritingStyle",
      selectOptionVariant: "option",
      description:
        "What writing style and voice should the content writer have? For videos this will affect the text that is narrated.",

      selectOptions: [
        {
          label: "Default",
          value: "content-processing-writing-style-default",
          description: "A neutral writing style",
        },
        {
          label: "Marketing",
          value: "content-processing-writing-style-marketing",
          description: "Catered towards sales, marketing, and promotion",
        },
        {
          label: "Roast Mode",
          value: "content-processing-writing-style-roast-mode",
          description:
            "Light-hearted and playful, makes jokes and uses exaggeration about the subject",
        },
        {
          label: "Upbeat",
          value: "content-processing-writing-style-upbeat",
          description:
            "Exudes positivity and energy. Incorporating optimistic language and a cheerful tone.",
        },
        {
          label: "Old and Wise",
          value: "content-processing-writing-style-old-wise",
          description:
            "Speaks from experience. Offering timeless wisdom or advice. A guru that has wise wisdom about the subject",
        },
        {
          label: "Friendly and Wholesome",
          value: "content-processing-writing-style-friendly-wholesome",
          description:
            "Radiates warmth and kindness. promoting positivity and a sense of community, using language that is supportive to make everyone feel welcome",
        },
        {
          label: "Sarcastic",
          value: "content-processing-writing-style-sarcastic",
          description:
            "Uses irony and exaggeration. The intended meaning of the words sometimes used is opposite to their literal meaning",
        },
      ],
      transformer: (x: any) => x,
    },
    {
      type: "divider",
      uniqueId: uuid(),
      nonValueField: true,
    },
    {
      type: "toggle",
      uniqueId: "content-processing-image-checkbox",
      text: "Generate Images",
      serviceKey: "shouldGenerateImages",
      nonValueField: true,
      required: false,
      description: "Do you want the generated content to have an image",
    },
  ],
};

export default contentProcessingStep;
