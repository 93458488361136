import { Box} from "@cloudscape-design/components";
import  { useContext } from "react";
import { FormFieldItem } from "../formGeneratorConstants";
import { FormContext } from "../FormContext";

interface Props {
  item: FormFieldItem;
}

const ReviewStepItem = ({ item }: Props) => {
  const { humanReadableFormValues } = useContext(FormContext);

  const valueItem = humanReadableFormValues[item.uniqueId];

  if (
    valueItem == null ||
    (valueItem.value == null && valueItem.valueDescription == null)
  ) {
    return null;
  }

  return (
    <Box>
      {
        <Box variant="awsui-key-label">
          {valueItem?.label ?? valueItem.labelDescription ?? "-"}
        </Box>
      }
      {valueItem.value && <Box>{valueItem.value}</Box>}

      {valueItem.valueDescription && (
        <Box color="text-status-inactive" fontWeight="light">
          {valueItem.valueDescription}
        </Box>
      )}
    </Box>
  );
};

export default ReviewStepItem;
