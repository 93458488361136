
import { WizardDefintion } from "../../wizardGenerator/formGeneratorConstants";
import contentDestinationStep from "./contentDestinationStep";
import contentProcessingStep from "./contentProcessingStep";
import contentSchedulingStep from "./contentSchedulingStep";
import eventSourceStep from "./eventSourceStep";
import frequencyStep from "./frequencyStep";
import imageOptionsStep from "./imageOptionsStep";
import { inputSourceStepWithConditionals } from "./inputSourceStep";
import jobPriorityStep from "./jobPriorityStep";

const jobCreationWizard: WizardDefintion = {
    frequency: frequencyStep,
    eventBasedSource: eventSourceStep,
    inputBasedSource: inputSourceStepWithConditionals,
    process: contentProcessingStep,
    imageOptionsStep: imageOptionsStep,
    schedule: contentSchedulingStep,
    jobPriorityStep:jobPriorityStep,
    destinations: contentDestinationStep,

  };
  
  export default jobCreationWizard