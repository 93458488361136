import {
  Box,
  Button,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormContext, ValidateStepResult } from "./FormContext";
import { useContext,  useState } from "react";
import { FormStep } from "./formGeneratorConstants";
import RightAlignedContent from "../../layout/RightAlignedContent";
import { useNavigate } from "react-router-dom";

interface Props {
  currentStep: FormStep;
  stepsArr: FormStep[];
  alignButtons?: "left" | "right";
}

const WizardFooterButtons = ({
  currentStep,
  stepsArr,
  alignButtons = "right",
}: Props) => {
  const {
    validateAllSteps,
    validateStep,
    setActiveStepIndex,
    activeStepIndex,
    onSubmit,
  } = useContext(FormContext);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const onNextClick = () => {
    validateStep(currentStep).then((stepStatus) => {
      if (stepStatus.isValid) {
        setActiveStepIndex((prev) => prev + 1);
      }
    });
  };
  const onPrevClick = () => {
    setActiveStepIndex((prev) => prev - 1);
  };

  const onSubmitInternal = () => {
    validateAllSteps(stepsArr).then((res: ValidateStepResult[]) => {
      setIsLoading(true);
      onSubmit(res)
        .then((resp) => {
          navigate(`/tools/jobs/${resp.id}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const ContainerType = alignButtons === "right" ? RightAlignedContent : Box;
  return (
    <ContainerType>
      <SpaceBetween size="xs" direction="horizontal">
        {activeStepIndex > 0 && <Button onClick={onPrevClick}>Previous</Button>}
        {activeStepIndex < stepsArr.length - 1 && (
          <Button variant="primary" onClick={onNextClick}>
            Next
          </Button>
        )}
        {activeStepIndex === stepsArr.length - 1 && (
          <Button
            loading={isLoading}
            variant="primary"
            onClick={onSubmitInternal}
          >
            Submit
          </Button>
        )}
      </SpaceBetween>
    </ContainerType>
  );
};

export default WizardFooterButtons;
