import {
    Box,
    Container,
    Header,
    Pagination,
    SpaceBetween,
    Table,
  } from "@cloudscape-design/components";
  import { useCollection } from '@cloudscape-design/collection-hooks';
  import { Link } from "react-router-dom";
  import { camelCaseToWords, capsToTitleCase } from "../../../common/textUtils";

  import ReactTimeAgo from "react-timeago"
  
 
  interface Props{
    jobs: any[]
    loading?: boolean
    headerText:string
  }
  const createdTimeColumn = {
    id: "createdTime",
    header: "Created time",
    cell: (item:any) =>  item.createdTime != null ? <ReactTimeAgo date={new Date(item.createdTime)} />  :  "-",
    sortingField: "createdTime",
  }
  const columnDefinitions = [
    {
      id: "id",
      header: "Id",
      cell: (item:any) => (
        <Link
          to={
            item.id != null ? `/tools/jobs/${item.id}` : "#"
          }
        >
          {item.id ?? "-"}
        </Link>
      ),
    },
    {
      id: "type",
      header: "Type",
      cell: (item:any) => camelCaseToWords(item.type)  || "-",
    },
    createdTimeColumn,
    {
      id: "status",
      header: "Status",
      cell: (item:any) => item.status || "-",
    },
  ]


  const JobSectionContainer = ({jobs, headerText}:Props) => {
    const { items, paginationProps  } = useCollection(
        jobs,
        {
          pagination: { pageSize: 5 },
          sorting: { defaultState: { sortingColumn: createdTimeColumn, isDescending: true }},
          selection: {},
        }
      );
        
    return (
        <>
          <Container>
            <Table
              header={
                <Header variant="h3">{capsToTitleCase(headerText)}</Header>
              }
              variant="embedded"
              columnDefinitions={columnDefinitions}
              items={items}
              loadingText="Loading jobs"
              sortingDescending
              sortingDisabled
              sortingColumn={createdTimeColumn}
              empty={
                <Box
                  margin={{ vertical: "xs" }}
                  textAlign="center"
                  color="inherit"
                >
                  <SpaceBetween size="m">
                    <b>No resources</b>
                  </SpaceBetween>
                </Box>
              }
              pagination={<Pagination {...paginationProps} />}
            />
          </Container>
        </>
      );
  };
  
  export default JobSectionContainer;
  