import {
  ContentLayout,
  Header,
} from "@cloudscape-design/components";
import WizardGenerator from "../wizardGenerator/WizardGenerator";
import jobCreationWizard from "../formJson/createWizard/jobCreationWizardBase";


const CreateJobWizard = () => {
  return (
    <ContentLayout header={<Header>Create Job Wizard</Header>}>
      <WizardGenerator wizardDefinition={jobCreationWizard} />
    </ContentLayout>
  );
};

export default CreateJobWizard;
