import "../../assets/css/tailwind.css";
import "../../assets/css/materialdesignicons.min.css";
import { TypeAnimation } from "react-type-animation";
import { useNavigate } from "react-router-dom";
import { Box } from "@cloudscape-design/components";
import { Button } from "@mantine/core";

export const HomeHero = () => {
  const navigate = useNavigate();
  return (
    <section className="relative overflow-hidden pt-48 after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-[56rem] after:h-[56rem] after:bg-gradient-to-tl after:to-amber-400/30  after:from-fuchsia-600/30 dark:after:to-amber-400/50 dark:after:from-fuchsia-600/50 after:blur-[200px] after:rounded-full after:-z-1">
      <div className="container relative z-2">
        <div className="grid grid-cols-1 text-center">
          <div className="">
            <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-6xl mb-5">
              The AI Content <br /> Platform for
              <TypeAnimation
                sequence={[
                  "Videos",
                  1000,
                  "Modern Teams",
                  1000,
                  "Social Media",
                  1000,
                ]}
                wrapper="span"
                speed={10}
                className="typewrite bg-gradient-to-br from-amber-400 to-fuchsia-600 text-transparent bg-clip-text ms-4"
                repeat={Infinity}
              />
            </h4>
            <Box variant="p">
              Artificial intelligence makes it fast easy to create content for
              your blog, social media, website, and more!
            </Box>

            <Box padding="l">
              <Button
              variant="gradient"
                onClick={() => {
                  navigate("/login");
                }}
                size="xl"
              >
                Request Beta Access
              </Button>
            </Box>
          </div>
          <div className="relative mt-8 z-3">
            <img
              src="https://mortal-react.vercel.app/static/media/classic01.edf4f10249ea99f2f0e0.png"
              alt=""
              className="mover"
            />
          </div>
        </div>
      </div>

      <div className="relative after:content-[''] after:absolute lg:after:-bottom-40 after:-bottom-28 after:end-0 after:start-0 after:mx-auto xl:after:w-[56rem] lg:after:w-[48rem] md:after:w-[32rem] after:w-[22rem] xl:after:h-[56rem] lg:after:h-[48rem] md:after:h-[32rem] after:h-[22rem] after:border-2 after:border-dashed after:border-slate-700/10 dark:after:border-slate-200/10 after:rounded-full after:-z-1 before:content-[''] before:absolute lg:before:-bottom-72 before:-bottom-56 before:end-0 before:start-0 before:mx-auto xl:before:w-[72rem] lg:before:w-[64rem] md:before:w-[48rem] before:w-[24rem] xl:before:h-[72rem] lg:before:h-[64rem] md:before:h-[48rem] before:h-[24rem] before:border-2 before:border-dashed before:border-slate-700/10 dark:before:border-slate-200/10 before:rounded-full before:-z-1"></div>
    </section>
  );
};
