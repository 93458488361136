import {
    Box,
    Button,
    Container,
    FormField,
    Input,
    Select,
  } from "@cloudscape-design/components";
  import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
  import { useState } from "react";
  
  export enum ContentStyleTypes{
      UPBEAT = 'UPBEAT',
      MOODY = 'MOODY',
      HYPER = 'HYPER',
      LOTS_OF_EMOJIS = 'LOTS_OF_EMOJIS',
      ROAST_MODE = 'ROAST_MODE',
      OLD_AND_WISE = 'OLD_AND_WISE',
      SATIRICAL = 'SATIRICAL',
      FRIENDLY_WHOLESOME = 'FRIENDLY_WHOLESOME',
      SASSY = 'SASSY',
  }
  interface Props {
    onChange: (val?: string) => void;
    isLoading?: boolean;
  }
  const options = [
    { label: "Upbeat (default)", value: ContentStyleTypes.UPBEAT },
    { label: "Friendly and Wholesome", value: ContentStyleTypes.FRIENDLY_WHOLESOME },
    { label: "Hyper", value: ContentStyleTypes.HYPER },
    { label: "Lots of Emojis", value: ContentStyleTypes.LOTS_OF_EMOJIS },
    { label: "Moody", value: ContentStyleTypes.MOODY },
    { label: "Old and Wise", value: ContentStyleTypes.OLD_AND_WISE },
    { label: "Roast Mode", value: ContentStyleTypes.ROAST_MODE },
    { label: "Sassy", value: ContentStyleTypes.SASSY },
    { label: "Satirical", value: ContentStyleTypes.SATIRICAL },
  ]
  const ContentStyleSelector = ({ onChange, isLoading =false }: Props) => {
    const [selectedOption, setSelectedOption] = useState<OptionDefinition | null>(() => {
        onChange(options[0].value)
        return options[0]
    });
  
  
    return (
      <FormField label="Content style" description="The style for the generated content">
        <Select
          selectedOption={selectedOption}
          disabled={isLoading}
          onChange={({ detail }) => {
              setSelectedOption(detail.selectedOption)
              onChange(detail.selectedOption.value)
          }}
          options={options}
        />
      </FormField>
    );
  };
  
  export default ContentStyleSelector;
  