import { Container, Grid } from "@cloudscape-design/components";
import { FormContext } from "./FormContext";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { WizardDefintion } from "./formGeneratorConstants";
import {
  clearErrorsForNotVisibleFields,
  isStepValid,
  showFieldOrStep,
} from "./formGeneratorUtils";
import WizardStepContainer from "./WizardStepContainer";
import WizardStepperControl from "./WizardStepperControl";
import { useMediaQuery } from "react-responsive";
import useDimensions from "../../../hooks/useDimensions";
interface Props {
  wizardDefinition: WizardDefintion;
  showSteps?: boolean;
}

const TwWizardVertical = ({ wizardDefinition, showSteps = true }: Props) => {

  const ref = useRef(null);
  const { width } = useDimensions(ref);

  const isMobile = useMemo(() => width <= 668, [width]);
  const { rawFormValues, setFormErrors, formErrors, activeStepIndex, setActiveStepIndex } = useContext(FormContext);

  const stepsArr = useMemo(() => {
    const steps = Object.values(wizardDefinition).filter((step) =>
      showFieldOrStep(step, rawFormValues)
    );
    steps.push({ type: "review", text: "Review", items: [] });
    return steps;
  }, [wizardDefinition, rawFormValues]);

  const currentStep = useMemo(
    () => stepsArr[activeStepIndex],
    [stepsArr, activeStepIndex]
  );

  useEffect(() => {
    const objWithErrorsCleared = clearErrorsForNotVisibleFields(
      formErrors,
      Object.values(wizardDefinition),
      rawFormValues
    );
    setFormErrors((prev: any) => ({ ...prev, ...objWithErrorsCleared }));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepsArr]);

  return (
    <div ref={ref}>
      <Grid
        gridDefinition={[
          { colspan: { default: 12, xs: 3, m: 2 } },
          { colspan: { default: 12, xs: 9, m: 10 } },
        ]}
      >
        <Container fitHeight variant="stacked">
          <WizardStepperControl
            currentStep={currentStep}
            stepsArr={stepsArr}
            orientation={isMobile ? "horizontal" : "vertical"}
            alternativeLabel={isMobile}
          />
        </Container>
        <WizardStepContainer
          currentStep={currentStep}
          stepsArr={stepsArr}
        />
      </Grid>
    </div>
  );
};

export default TwWizardVertical;
