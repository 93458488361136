export function camelCaseToWords(s: string) {
  try {
    const result = s.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  } catch (e) {
    return s;
  }
}
export function capsToTitleCase(str: string) {
  try {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  } catch (e) {
    return str;
  }
}

export function toTitleCase(s: string) {
  if (typeof s !== "string") {
    return s;
  }
  try {
    return s
      .replace(/([^A-Z])([A-Z])/g, "$1 $2") // split cameCase
      .replace(/[_\-]+/g, " ") // split snake_case and lisp-case
      .toLowerCase()
      .replace(/(^\w|\b\w)/g, function (m) {
        return m.toUpperCase();
      }) // title case words
      .replace(/\s+/g, " ") // collapse repeated whitespace
      .replace(/^\s+|\s+$/, ""); // remove leading/trailing whitespace
  } catch (e) {
    return s;
  }
}
export function truncateString(str: string, num: number) {
  try {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  } catch (e) {
    return str;
  }
}
