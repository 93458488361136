import React, { useContext } from "react";

import { SpaceBetween } from "@cloudscape-design/components";
import { Button, MantineProvider } from "@mantine/core";
import { AuthContext } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import useFeatureFlags, { FeatureFlags } from "../../../hooks/useFeatureFlags";

export default function NavRightSection() {
  const { loggedIn } = useContext(AuthContext);
  const { isFeatureEnabled } = useFeatureFlags()
  const navigate = useNavigate();

  if(!loggedIn){
    return <Button onClick={() => navigate("/login")} variant="gradient">
    Login
  </Button>
  }
  if(!isFeatureEnabled(FeatureFlags.BETA_ACCESS)){
    return null;
  }
  return (
    <SpaceBetween direction="horizontal" size="m">
      <Button onClick={() => navigate("/tools")} variant="gradient">
          Tools
        </Button>
    </SpaceBetween>
  );
}
