import { Box, SpaceBetween } from "@cloudscape-design/components";
import { IconAugmentedReality } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  size?: "default" | "large";
  colorVariant?: "default" | "gradient";
}

const GradientText = styled(Box)<Props>`

  ${(props) =>
    props.colorVariant === "gradient"
      ? `
      background: linear-gradient(to bottom right, #fbbf24 0%, #c026d3 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `
      : ""}
`;
const GradientTextContainerBox = styled(Box)<Props>`
${(props) =>
  props.colorVariant === "gradient"
    ? `  display: flex;
    align-items: center;
    justify-content: center;
`
    : ""}

`;
const Logo = ({ size = "default", colorVariant = "default" }: Props) => {
  return (
    <Link to="/">
      <SpaceBetween size="xxs" direction="horizontal">
        <IconAugmentedReality  size={size === "large" ? 50 : 30} />
        <GradientTextContainerBox colorVariant={colorVariant}>
          <GradientText colorVariant={colorVariant} variant={size === "large" ? "awsui-value-large" : "h2"}>
            TaskWork.Ai
          </GradientText>
        </GradientTextContainerBox>
      </SpaceBetween>
    </Link>
  );
};

export default Logo;
