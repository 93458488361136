import { FormStep } from "../../wizardGenerator/formGeneratorConstants"

const jobPriorityStep : FormStep= {
    type: "section",
    text: "Job Priority",

    items: [  
       
      {
        type: "select",
        label: "Processing Priority",
        uniqueId: "job-priority-select",
        selectOptionVariant: "option",
        description:
          "How urgent is this content",
  
  
        selectOptions: [
          {
            label: "Low",
            value: "job-priority-low",
            description: "Process during off cycle hours and idle time"
          },
          {
            label: "Normal",
            value: "job-priority-normal",
            description: "Added to the queue immediately, processed based on queue position and resource availability"
          },
          {
            label: "High",
            value: "job-priority-high",
            description: "Processed immediately"
          },
         
        ],
        transformer: (x: any) => x,
      },
    ],
  }

  export default jobPriorityStep