import {
    ContentLayout,
    Header,
  } from "@cloudscape-design/components";
import WizardGenerator from "../wizardGenerator/WizardGenerator";
import jobCreationWizard from "../formJson/createWizard/jobCreationWizardBase";
import socialSchedulerWizard from "../formJson/socialScheduler/socialSchedulerWizard";
  
  
  const SocialMediaScheduleWizard = () => {
    console.log(socialSchedulerWizard)
    return (
      <ContentLayout header={<Header>Create Job Wizard</Header>}>
        <WizardGenerator wizardType="stepper" orientation="vertical" wizardDefinition={socialSchedulerWizard} />
      </ContentLayout>
    );
  };
  
  export default SocialMediaScheduleWizard;
  