import { ContentLayout, Header } from "@cloudscape-design/components";
import SingleFieldInputForm from "../common/SingleFieldInputForm";
import { getApiDomain } from "../../../config/envConfig";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useApiRequest from "../../../hooks/useApiRequest";

const AudioTranscribe = () => {
  const {apiRequest} = useApiRequest()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const submit = (val: string) => {
    setIsLoading(true)
    apiRequest(`${getApiDomain()}/jobs`,'POST',{ mp3Url: val, type: "AudioTranscribe"})
    .then((resp) => {
      navigate(`/tools/jobs/${resp.id}`)
    })
    .finally(() => {
      setIsLoading(false)
    })
    console.log(val)
  };
  return (
    <ContentLayout header={<Header>Audio Transcribe</Header>}>
      <SingleFieldInputForm onSubmit={submit} label="Mp3 Url" />
    </ContentLayout>
  );
};

export default AudioTranscribe;
