
import { WizardDefintion } from "../../wizardGenerator/formGeneratorConstants";
import contentProcessingStep from "../createWizard/contentProcessingStep";
import imageOptionsStep from "../createWizard/imageOptionsStep";
import { inputSourceStep } from "../createWizard/inputSourceStep";
import  simpleContentProcessingStep  from "../createWizard/simpleContentProcessingStep";


const socialSchedulerWizard: WizardDefintion = {
    
    contentProcessingStep: simpleContentProcessingStep,
    inputBasedSource: inputSourceStep,
    imageOptionsStep: imageOptionsStep
  };
  
  export default socialSchedulerWizard