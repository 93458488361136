import {
  Modal,
} from "@cloudscape-design/components";
import { GoogleLogin } from "@react-oauth/google";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
interface Props {
  visible: boolean;
  onDismiss?: () => void;
}
export const LoginModal = ({ visible, onDismiss }: Props) => {
  const { processToken, loggedIn } = useContext(AuthContext);
  if (!visible || loggedIn) {
    return null;
  }
  return (
    <Modal visible={visible} size="small" onDismiss={onDismiss}>
      <GoogleLogin
        width={275}
        onSuccess={(credentialResponse) => {
          processToken(credentialResponse.credential!);

          if(onDismiss != null){
            onDismiss()
          }

        }}
        onError={() => {
          console.log("Login Failed");
          if(onDismiss != null){
            onDismiss()
          }
        }}
      />
    </Modal>
  );
};

export default LoginModal;

