import {
  Box,
  Container,
} from "@cloudscape-design/components";
import { GoogleLogin } from "@react-oauth/google";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { styled } from "styled-components";
import { Navigate, useNavigate } from "react-router";
import { Center } from "@mantine/core";
import Logo from "./Logo";

const StyledLoginPage = styled.div`
  margin: 0;
  padding: 0;
  display: grid;
  place-content: center;
  min-height: 100vh;
`;
const StyledCentered = styled.div`
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`;
const StyledLoginContainer = styled(Container)``;
export const Login = () => {
  const { processToken, loggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  console.log(loggedIn);
  if (loggedIn) {
    return <Navigate to={"/tools"} />;
  }
  return (
    
    <section className="relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-[56rem] after:h-[56rem] after:bg-gradient-to-tl after:to-amber-400/30  after:from-fuchsia-600/30 dark:after:to-amber-400/50 dark:after:from-fuchsia-600/50 after:blur-[200px] after:rounded-full after:-z-1">
      <div className="container ">
        <StyledLoginPage>
          <StyledCentered>
            <StyledLoginContainer
              header={
                <Center>
                  <Logo size="large" colorVariant="gradient" />
                </Center>
              }
            >
              <Box margin={{ vertical: "xxl" }}>
                <Center>
                  <GoogleLogin
                    size="large"
                    onSuccess={(credentialResponse) => {
                      processToken(credentialResponse.credential!);
                      navigate("/tools");
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                    width={300}
                    shape="rectangular"
                    auto_select
                  />
                </Center>
              </Box>
            </StyledLoginContainer>
          </StyledCentered>
        </StyledLoginPage>
      </div>
    </section>
  );
};

export default Login;
