import { FormStep } from "../../wizardGenerator/formGeneratorConstants"

const eventSourceStep: FormStep = {
  type: "section",
  text: "Event Based Source",
  conditionals: [
    {
      parentId: "content-frequency-type-select",
      parentIdValueProperty: "tag",
      condition: "event-based",
      type: "required",
    },
    {
      parentId: "content-frequency-type-select",
      parentIdValueProperty: "tag",
      condition: "event-based",
      type: "show",
    },
  ],
  items: [
    {
      type: "select",
      label: "Event Source",
      uniqueId: "content-event-source-select",
      description: "Where should we get the inspiration for your content?",
      required: true,
      selectOptions: [
        {
          label: "Youtube",
          value: "content-event-source-youtube",
          description:
            "Content will be generated as new videos are published/added to a specific Youtube source such as a playlist or channel",
        },
        {
          label: "RSS Feed",
          description:
            "Content will be generated as new entries are published into the podcast RSS feed.",
          value: "content-event-source-rss",
        },
        {
          label: "Social Media",

          description:
            "Content will be generated as the social media source receives new posts or mentions.",
          value: "content-event-source-social",
        },
        {
          label: "Webhook",
          value: "source-misc-webhook",
          description:
            "We will provide you a webhook URL where you can send events for processing",
        },
      ],
      transformer: (x: any) => x,
    },
    {
      type: "select",
      label: "Youtube Source",
      uniqueId: "content-source-youtube-select",
      description: "What source on Youtube would you like to use for content",
      conditionals: [
        {
          parentId: "content-event-source-select",
          parentIdValueProperty: "value",
          condition: "content-event-source-youtube",
          type: "required",
        },
        {
          parentId: "content-event-source-select",
          parentIdValueProperty: "value",
          condition: "content-event-source-youtube",
          type: "show",
        },
      ],
      selectOptions: [
        {
          label: "Channel",
          value: "content-event-source-youtube-channel",
          description:
            "Content will be generated as new videos are published/added to a specific channel.",
        },
        {
          label: "Playlist",
          value: "content-event-source-youtube-playlist",
          description:
            "Content will be generated as new videos are published/added to a specific playlist.",
        },
      ],
      transformer: (x: any) => x,
    },
    {
      type: "url",
      label: "Youtube Channel URL",
      uniqueId: "content-source-youtube-channel-url",
      placeholder: "https://youtube.com/1234567890",
      conditionals: [
        {
          parentId: "content-source-youtube-select",
          condition: "content-event-source-youtube-channel",
          parentIdValueProperty: "value",
          type: "required",
        },
        {
          parentId: "content-source-youtube-select",
          parentIdValueProperty: "value",
          condition: "content-event-source-youtube-channel",
          type: "show",
        },
      ],

      transformer: (x: any) => x,
    },
    {
      type: "url",
      label: "Youtube Playlist URL",
      uniqueId: "content-source-youtube-playlist-url",
      placeholder: "https://youtube.com/1234567890",
      conditionals: [
        {
          parentId: "content-source-youtube-select",
          condition: "content-event-source-youtube-playlist",
          parentIdValueProperty: "value",
          type: "required",
        },
        {
          parentId: "content-source-youtube-select",
          parentIdValueProperty: "value",
          condition: "content-event-source-youtube-playlist",
          type: "show",
        },
      ],

      transformer: (x: any) => x,
    },
    {
      type: "select",
      label: "RSS Feed Source",
      uniqueId: "content-event-source-rss-select",
      description: "What RSS feed would you like to use for content",
      conditionals: [
        {
          parentId: "content-event-source-select",
          parentIdValueProperty: "value",
          condition: "content-event-source-rss",
          type: "required",
        },
        {
          parentId: "content-event-source-select",
          parentIdValueProperty: "value",
          condition: "content-event-source-rss",
          type: "show",
        },
      ],
      selectOptions: [
        {
          label: "Audio",
          value: "content-event-source-rss-podcast",
          description:
            "An RSS feed with of audio URLs, such as a podcast feed",
          tag: "rss",
        },
        {
          label: "Text",
          value: "content-event-source-rss-text",
          description:
            "An RSS feed with of text, the text in the <content> or <body> of the RSS feed item itself will be used",
          tag: "rss",
        },
        {
          label: "Web Content",
          value: "content-event-source-rss-web",
          description:
            "An RSS feed with of web content links, we will pull the content from the URL/link in the RSS feed item",
          tag: "rss",
        },

      ],
      transformer: (x: any) => x,
    },
    {
      type: "select",
      label: "Social Media Source",
      uniqueId: "content-event-source-social-select",
      description: "What source on Youtube would you like to use for content",
      conditionals: [
        {
          parentId: "content-event-source-select",
          parentIdValueProperty: "value",
          condition: "content-event-source-social",
          type: "required",
        },
        {
          parentId: "content-event-source-select",
          parentIdValueProperty: "value",
          condition: "content-event-source-social",
          type: "show",
        },
      ],
      selectOptions: [
        {
          label: "X/Twitter Feed",
          value: "content-event-source-social-twitter-feed",
          tag: "social",
        },
        {
          label: "X/Twitter Mentions",
          value: "content-event-source-social-twitter-mentions",
          tag: "social",
        },
        {
          label: "Facebook Mentions",
          value: "content-event-source-social-facebook-mentions",
          tag: "social",
        },
        {
          label: "Facebook Group Feed",
          value: "content-event-source-social-facebook-group-feed",
          tag: "social",
        },
      ],
      transformer: (x: any) => x,
    },
  ],
};

export default eventSourceStep;
