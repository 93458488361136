import { Box, Button, Checkbox, Container, ContentLayout, FormField, Header, Input } from "@cloudscape-design/components";
import { useState } from "react";

const BlogArticle = () => {
  const [formContents,setFormContents] = useState<any>({})


  const updateValue = (val : any) => {
    setFormContents((prev:any) => {return {...prev,...val}})
  }

  const onSubmit = (val : any) => {
    console.log(val)
  }
  return (
    <ContentLayout header={<Header>Blog Article Creator</Header>}>
        <Container  footer={
        <Box textAlign="right">
          <Button
            onClick={() => {
              onSubmit(formContents);
            }}
          >
            Submit
          </Button>
        </Box>
      }>
          <FormField label="Topic">
            <Input value={formContents.topic ?? ""} onChange={(e:any) => updateValue({topic: e.detail.value})} />
          </FormField>
          <FormField label="Character Limit">
            <Input type="number" value={formContents.characterLimit ?? ""} onChange={(e:any) => updateValue({characterLimit: e.detail.value})} />
          </FormField>

          <FormField label="Has Images?">
            <Checkbox checked={formContents.hasImages ?? false}  onChange={(e:any) => updateValue({hasImages: e.detail.checked})}/>
           
          </FormField>
          {formContents.hasImages &&<FormField label="Image Limit">
            <Input type="number" value={formContents.imageLimit ?? ""} onChange={(e:any) => {
              if(e.detail.value < 1 || e.detail.value > 4){
                return
              }
              updateValue({imageLimit: e.detail.value})
            }
              } />
          </FormField>}
        </Container>
    </ContentLayout>
  );
};

export default BlogArticle