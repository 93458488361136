import {
  Box,
} from "@cloudscape-design/components";
import React, { ReactNode } from "react";

import { styled } from "styled-components";

interface Props {
  children: ReactNode;
}
const EvenlySpacedBox = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const EvenlySpacedContentsBox = ({ children }: Props) => {
  return (
    <EvenlySpacedBox>
      {React.Children.map(children, (child, i) => {
        return (
          <React.Fragment>
            <div key={i}>{child}</div>
          </React.Fragment>
        );
      })}
    </EvenlySpacedBox>
  );
};

export default EvenlySpacedContentsBox;
