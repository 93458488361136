import {
  Box,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Container as MantineContainer } from "@mantine/core";

export const BetaAccessThanks = () => {


  return (
    <Box margin={{bottom:"l"}}>
      <section className="relative overflow-hidden pt-48 after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-[56rem] after:h-[56rem] after:bg-gradient-to-tl after:to-amber-400/30  after:from-fuchsia-600/30 dark:after:to-amber-400/50 dark:after:from-fuchsia-600/50 after:blur-[200px] after:rounded-full after:-z-1">
        <div className="container relative z-2">
          <div className="grid grid-cols-1 text-center">
            <div className="">
              <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-6xl mb-5">
                Welcome to TaskWork.Ai! 🚀
              </h4>
            </div>
          </div>
        </div>
        <MantineContainer>
          <SpaceBetween size="l">
            <Container
              header={<Header variant="h1">You're On The List!</Header>}
            >
              Hey there, visionary! You’ve taken the first step into a broader
              world with [Your Product Name], and we couldn’t be more thrilled
              to have you aboard. You’re now officially on our beta access list,
              which is pretty much the digital equivalent of a golden ticket. 🎟️
            </Container>
            <Container
              header={<Header variant="h1">Here's What Happens Next:</Header>}
            >
              Our team is buzzing with excitement as we put the finishing
              touches on [Your Product Name], ensuring it’s polished and ready
              to revolutionize [specific problem or industry]. We’re all about
              creating an exceptional experience for you, and that means getting
              everything just right.
            </Container>
            <Container
              header={<Header variant="h1">Beta Access Timeline:</Header>}
            >
              We’re rolling out access in waves to give everyone the smoothest
              experience possible. While we can’t give you a magic date just
              yet, rest assured, you’ll be among the first to know when your
              access is unlocked.
            </Container>
            <Container header={<Header variant="h1">Stay Tuned:</Header>}>
              Keep an eye on your inbox! We’ll be sending out regular updates
              about our progress, sneak peeks behind the scenes, and maybe even
              a few surprises along the way. 💌
            </Container>
            <Container
              header={
                <Header variant="h1">A Little Patience, A Lot of Magic:</Header>
              }
            >
              We understand waiting can be the hardest part, but we promise it’s
              worth it. Your feedback will be instrumental in shaping [Your
              Product Name] into the game-changing tool we know it can be. We’re
              not just building a product; we’re building a community, and that
              starts with you.
            </Container>
          </SpaceBetween>
        </MantineContainer>
      </section>
    </Box>
  );
};

export default BetaAccessThanks;
