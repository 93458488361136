import { Orientation } from "@mui/material";
import FormContextProvider, { FormContext, ValidateStepResult } from "./FormContext";
import TwForm from "./TwForm";
import TwWizard from "./TwWizard";
import TwWizardVertical from "./TwWizardVertical";
import { WizardDefintion } from "./formGeneratorConstants";
import useApiRequest from "../../../hooks/useApiRequest";
import { getApiDomain } from "../../../config/envConfig";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  wizardDefinition: WizardDefintion;
  showSteps?: boolean;
  wizardType?: "stepper" | "form";
  orientation?: Orientation;
}

const WizardGenerator = ({
  wizardDefinition,
  showSteps = true,
  wizardType = "stepper",
  orientation = "horizontal",
}: Props) => {
  const {apiRequest} = useApiRequest()
  const {rawFormValues} = useContext(FormContext)
  const onSubmit =  async   (res: ValidateStepResult[]) => {
    const isValid = res.every((x) => x.isValid);
    if (isValid) {
        return apiRequest(`${getApiDomain()}/custom_job`, "POST", {
          
          createdTime: new Date().getTime(),
          ...rawFormValues
        })
    } else {
      console.log("not valid");
    }
    
  }
  
  if (wizardType === "stepper") {
    return (
      <FormContextProvider
      onSubmit={onSubmit}>
        {orientation === "vertical" ? (
          <TwWizardVertical
            showSteps={showSteps}
            wizardDefinition={wizardDefinition}
          />
        ) : (
          <TwWizard showSteps={showSteps} wizardDefinition={wizardDefinition} />
        )}
      </FormContextProvider>
    );
  }
  return (
    <FormContextProvider onSubmit={onSubmit} >
      <TwForm wizardDefinition={wizardDefinition} />
    </FormContextProvider>
  );
};

export default WizardGenerator;
