import { TopNavigation, TopNavigationProps } from "@cloudscape-design/components";
import { useContext, useMemo, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import LoginModal from "./LoginModal";
import { useNavigate } from "react-router";

export const TopNav = () => {
    const { loggedIn, logout} = useContext(AuthContext)
    const navigate = useNavigate()
    const [isLoginModalVisible,setIsLoginModalVisible] = useState(false)

    const utilityControls :TopNavigationProps.Utility[] = useMemo(() => {


        if(loggedIn){
            return [
                {
                    type: "button",
                    text: "Tools",
                    href: "/tools",
                    onClick: (e) => {
                        e.preventDefault()
                        navigate("/tools")
                    }
                  },
                  {
                    type: "button",
                    text: "Logout",
                    onClick: (e) => {
                        logout()
                    }
                  }

            ]
        }
        return [{
            type: "button",
            text: "Login",
            onClick: () => {
                setIsLoginModalVisible(true)
                
            }
            ,
          }]
    },[loggedIn])
  return (
    <>
    <LoginModal visible={isLoginModalVisible} onDismiss={() => 
        {
            setIsLoginModalVisible(false)
        }

        } />
    <TopNavigation
        
      identity={{
        href: "/",
        title: "TaskWork.Ai",

      }}
      utilities={utilityControls}
    /></>
  );
};
