import { FormStep } from "../../wizardGenerator/formGeneratorConstants"

const contentSchedulingStep : FormStep= {
    type: "section",
    text: "Scheduling",
    conditionals: [
        {
          parentId: "content-frequency-type-select",
          parentIdValueProperty: "value",
          condition: "content-frequency-type-scheduled",
          type: "required",
        },
        {
          parentId: "content-frequency-type-select",
          parentIdValueProperty: "value",
          condition: "content-frequency-type-scheduled",
          type: "show",
        },
      ],
    items: [],
  }

  export default contentSchedulingStep