import {
  Box,
  Button,
  Container,
  ContentLayout,
  ExpandableSection,
  FormField,
  Header,
  Input,
  RadioGroup,
  SpaceBetween,
  Textarea,
} from "@cloudscape-design/components";
import useApiRequest from "../../../hooks/useApiRequest";
import { useNavigate } from "react-router-dom";
import { getApiDomain } from "../../../config/envConfig";
import { useMemo, useState } from "react";
import ImageStyleSelector from "../common/ImageStyleSelector";
import ContentStyleSelector from "../common/ContentStyleSelector";

const sourceTypes: { [key: string]: any } = {
  youtube: {
    value: "youtube",
    label: "YouTube video",
    serviceTypeKey: "YoutubeBasedSocialMediaPost",
    serviceValueKey: "youtubeUrl",
    disabled: false,
    hidden: false,
    inputType: "url",
    placeholder: "https://youtube.com/123456",
  },
  mp3: {
    value: "mp3",
    label: "MP3",
    serviceTypeKey: "AudioBasedSocialMediaPost",
    serviceValueKey: "mp3Url",
    disabled: false,
    hidden: false,
    inputType: "url",
    placeholder: "https://example.com/123456.mp3",
  },
  text: {
    value: "text",
    label: "Text input",
    serviceTypeKey: "TextInputSocialMediaPost",
    serviceValueKey: "text",
    disabled: false,
    hidden: false,
    inputType: "text",
    placeholder: "Paste or enter text here",
  },
  webpage: {
    value: "webpage",
    label: "Webpage content",
    serviceTypeKey: "WebpageSummarize",
    serviceValueKey: "url",
    disabled: true,
    hidden: true,
    inputType: "url",
  },
  audioFile: {
    value: "audioFile",
    label: "Audio file upload",
    serviceTypeKey: "AudioFileSummarize",
    serviceValueKey: "audioFileLocation",
    disabled: true,
    hidden: true,
    inputType: "file",
  },
};

const SocialMediaPost = () => {
  const { apiRequest } = useApiRequest();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const [referenceValue, setReferenceValue] = useState("");
  const [imageStyle, setImageStyle] = useState<string | undefined>();
  const [contentStyle, setContentStyle] = useState<string | undefined>();
  const [type, setType] = useState<any>(sourceTypes.text);
  const submit = () => {
    setIsLoading(true);
    apiRequest(`${getApiDomain()}/jobs`, "POST", {
      referenceImage: referenceValue,
      createdTime: new Date().getTime(),
      [type.serviceValueKey]: value,
      type: type.serviceTypeKey,
      imageStyle,
      contentStyle,
    })
      .then((resp) => {
        navigate(`/tools/jobs/${resp.id}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
    console.log(type);
  };

  const sources = useMemo(() => {
    return Object.values(sourceTypes).filter((x) => x.hidden === false);
  }, []);

  const disabledButton = useMemo(
    () => type.length === 0 || value.length === 0,
    [type, value]
  );

  return (
    <ContentLayout header={<Header>Social media post generator</Header>}>
      <Container
        footer={
          <Box textAlign="right">
            <Button
              loading={isLoading}
              onClick={submit}
              disabled={disabledButton}
            >
              Submit
            </Button>
          </Box>
        }
      >
        <SpaceBetween size="m">
          <FormField
            label="Source type"
            description="The type of source for your post"
          >
            <RadioGroup
              onChange={({ detail }) => {
                setType((prev: any) => {
                  if (prev.inputType !== sourceTypes[detail.value].inputType) {
                    setValue("");
                  }
                  return sourceTypes[detail.value];
                });
              }}
              value={type.value}
              items={sources}
            />
          </FormField>
          {type.inputType === "text" ? (
            <FormField
              label="Text"
              description="The context text to base your social media post on"
              stretch
            >
              <Textarea
                placeholder={type.placeholder}
                rows={10}
                value={value}
                onChange={(e) => {
                  setValue(e.detail.value);
                }}
              />
            </FormField>
          ) : (
            <FormField
              label="Source URL"
              description="Where should we get the source media"
            >
              <Input
                placeholder={type.placeholder}
                value={value}
                onChange={(e) => {
                  setValue(e.detail.value);
                }}
              />
            </FormField>
          )}
          <ExpandableSection variant="footer" headerText="Advanced Options" defaultExpanded>
            <ImageStyleSelector onChange={(e) => setImageStyle(e)} />
            <ContentStyleSelector onChange={(e) => setContentStyle(e)} />
            <FormField
              label="Reference Image URL"
              description="Use a reference image for inspiration of the AI generated image"
            >
              <Input
                value={referenceValue}
                onChange={(e) => {
                  setReferenceValue(e.detail.value);
                }}
              />
            </FormField>
          </ExpandableSection>
        </SpaceBetween>
      </Container>
    </ContentLayout>
  );
};

export default SocialMediaPost;

//youtubebasedsocialmediapost
