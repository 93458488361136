import {
  Conditional,
  FormFieldItem,
  FormStep,
  NON_VALIDATION_FIELD_TYPES,
} from "./formGeneratorConstants";
import validUrl from "valid-url"
export const getErrorTexts = (
  field: any,
  formErrors: { [key: string]: string[] }
) => {
  const errors = formErrors[field.uniqueId];
  if (errors == null || errors.length === 0) {
    return [];
  }
  return errors;
};

export const isStepValid = async (values: any, step: FormStep) => {
  const stepErrors = getErrorsForStep(values, step);
  const hasErrors = Object.values(stepErrors).some((x) => x.length > 0);
  return { isValid: !hasErrors, errors: stepErrors };
};

//this is so that we can clear any errors for fields that get hidden
//this is needed because required fields aren't required unless theyre visible 
export const clearErrorsForNotVisibleFields = (formErrors: { [key: string]: string[] }, steps: FormStep[], values: any ) => {
  
  const newFormErrorObj = {...formErrors}
  const errorKeys = new Set(Object.keys(formErrors))
  steps.forEach(step => {
    step.items.forEach(fieldItem => {
      if(errorKeys.has(fieldItem.uniqueId) && !showFieldOrStep(fieldItem, values,step)){
        newFormErrorObj[fieldItem.uniqueId] = []
      }
    })

  })

  return newFormErrorObj
}
export const getErrorsForStep = (values: any, step: FormStep) => {
  if (!showFieldOrStep(step, values)) {
    return {};
  }
  const visibleFields = step.items.filter((x) => showFieldOrStep(x, values));
  const stepErrors: { [key: string]: string[] } = {};
  visibleFields.forEach((x) => {
    const fieldErrors = getErrorsForField(values, x);
    stepErrors[x.uniqueId] = fieldErrors;
  });
  return stepErrors;
};

export const getErrorsForField = (values: any, formField: FormFieldItem) => {
  //if this is a field type that doesn't require validation, such as divider or markdown
  //return no errors
  if (NON_VALIDATION_FIELD_TYPES.has(formField.type)) {
    return [];
  }


  //get the field value
  const fieldValue = values[formField.uniqueId];
  const errors = [];
  if(fieldValue?.length > 0 && formField.type === "url"){
    if(!validUrl.isUri(fieldValue)){
      errors.push("The input must be a valid url");
    }
  }
  //if the step is required and we don't have a value
  if (
    isFieldOrStepRequired(formField, values) &&
    (fieldValue == null || fieldValue.length === 0)
  ) {
    errors.push("This field is required");
  }

  //if the field has custom validations
  if (formField.validations != null) {
    //check custom validations, add error text for any that fail
    formField.validations.forEach((x) => {
      if (!x.validationFunction(fieldValue)) {
        errors.push(x.errorText);
      }
    });
  }
  return errors;
};

export const isFieldValid = (values: any, formField: FormFieldItem) => {
  const fieldErrors = getErrorsForField(values, formField);

  return { isValid: fieldErrors.length === 0, errors: fieldErrors };
};

export const evaluateConditional = (
  conditionals: Conditional[],
  values: any
) => {
  return conditionals.every((x) => {
    if (values[x.parentId] == null) {
      return false;
    }
    let effectiveValue = values[x.parentId];
    if (
      x.parentIdValueProperty != null &&
      values[x.parentId][x.parentIdValueProperty] != null
    ) {
      effectiveValue = values[x.parentId][x.parentIdValueProperty];
    }

    return x.parentId.length > 0 && effectiveValue === x.condition;
  });
};

export const showFieldOrStep = (
  fieldOrStep: FormFieldItem | FormStep,
  values: any,
  parent?: FormFieldItem | FormStep,

) => {
  //if the parent is not null and the parent is hidden, this field is hidden
  if(parent != null && !showFieldOrStep(parent,values)){
    return false
  }
  if (fieldOrStep == null || fieldOrStep.conditionals == null) {
    return true;
  }
  const shouldShow = evaluateConditional(
    fieldOrStep.conditionals.filter((x) => x.type === "show"),
    values
  );
  //if the field isn't shown, clear its value
  if (!shouldShow && fieldOrStep.uniqueId != null) {
    //console.log(fieldOrStep.uniqueId)
    values[fieldOrStep.uniqueId] = undefined;
  }
  return shouldShow;
};



export const isFieldOrStepRequired = (
  fieldOrStep: FormFieldItem | FormStep,
  values: any
) => {
  //if were not showing the field, it is not required
  if (!showFieldOrStep(fieldOrStep, values)) {
    return false;
  }

  if(fieldOrStep.required != null){
    return fieldOrStep.required 
  }

  if (fieldOrStep == null || fieldOrStep.conditionals == null) {
    return false;
  }

  return evaluateConditional(
    fieldOrStep.conditionals.filter((x) => x.type === "required"),
    values
  )
};
export const getUniqueIdForField = (field: FormFieldItem) => {
  return field.id || field.uniqueId || `${field.label} - ${Math.random()}`;
};
