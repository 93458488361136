import React, { useEffect, useState } from "react";


import { Box, SpaceBetween } from "@cloudscape-design/components";
import EvenlySpacedContentsBox from "../EvenlySpacedContentsBox";
import Logo from "../Logo";
import NavRightSection from "./NavRightSection";

export default function Navbar() {

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    //activateMenu()
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <>
      <nav
        id="topnav"
        className={`${scroll ? "nav-sticky" : ""} defaultscroll is-sticky`}
      >
        <div className="container">
          <EvenlySpacedContentsBox>
            <Box margin="l">
            <Logo  />
            </Box>
            <Box margin="l">
              <NavRightSection />
            </Box>       
          </EvenlySpacedContentsBox>
        </div>
      </nav>
    </>
  );
}
