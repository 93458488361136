import {
  Box,
  ColumnLayout,
  Container,
  ContentLayout,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import useToolsConfig from "../../hooks/useToolsConfig";
import { Link } from "react-router-dom";
import styled from "styled-components";

const FullHeightContainer = styled(Container)`
  [class^="awsui_root"] {
    height: 100% !important;
  }
`
const ToolsHome = () => {
  const { categorizedToolsConfig } = useToolsConfig();

  return (
    <ContentLayout header={<Header>Tools</Header>}>
      <SpaceBetween size="m">
        <ColumnLayout columns={3}>
          {Object.entries(categorizedToolsConfig).sort((entry1: any[], entry2: any[]) => entry1[1].length - entry2[1].length).map((entry: any[], i) => {
            const category: string = entry[0];
            const items: any[] = entry[1];
            return (
              <FullHeightContainer key={i} header={<Header variant="h2">{category}</Header>}>
                
                  {items.map((item,x) => (
                    <Box key={x}>
                      <Link to={item.href ?? "#"}>{item.title}</Link>
                    </Box>
                  ))}
              </FullHeightContainer>
            );
          })}
        </ColumnLayout>
      </SpaceBetween>
    </ContentLayout>
  );
};

export default ToolsHome;
