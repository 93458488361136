import {
  Container,
  ContentLayout,
  Header,
} from "@cloudscape-design/components";
import SingleFieldInputForm from "../common/SingleFieldInputForm";
import useApiRequest from "../../../hooks/useApiRequest";
import { getApiDomain } from "../../../config/envConfig";
import { useNavigate } from "react-router-dom";

export const AudioSummarize = () => {
  const {apiRequest} = useApiRequest()
  const navigate = useNavigate()
  const submit = (val: string) => {
    apiRequest(`${getApiDomain()}/jobs`,'POST',{ mp3Url: val, type: "AudioSummarize"})
    .then((resp) => {
      navigate(`/tools/jobs/${resp.id}`)
    })
    console.log(val)
  };
  return (
    <ContentLayout header={<Header>Audio Summarize</Header>}>
      <SingleFieldInputForm onSubmit={submit} label="Mp3 Url" />
    </ContentLayout>
  );
};

export default AudioSummarize;
