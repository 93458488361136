import { FormStep } from "../../wizardGenerator/formGeneratorConstants";
import { v4 as uuid } from "uuid";
const simpleContentProcessingStep: FormStep = {
  type: "section",
  text: "Content processing",
  required: true,
  items: [
    {
      type: "select",
      label: "Type",
      uniqueId: "content-processing-type-select",
      description: "What type of content should we generate?",
      defaultValue: "processing-type-social",
      required: true,
      selectOptions: [
        {
          label: "Social Media Post",
          description:
            "A social media post tailored to the platform of your choosing.",
          value: "processing-type-social",
          tag: "text",
        },

        {
          label: "Video (Coming soon)",
          disabled: true,
          description: "Video based on the source content.",
          value: "processing-type-video",
          tag: "video",
        },
        {
          label: "Text",
          description:
            "Long form text such as blog posts, transcription, notes, and summaries.",
          value: "processing-type-long-form-text",
          tag: "text",
        },
      ],
      transformer: (x: any) => x,
    },
    {
      type: "toggle",
      uniqueId: "content-processing-advanced-mode-checkbox",
      text: "Advanced Options",
      serviceKey: "advancedOptions",
      required: false,
      description: "Customize your output",
      nonValueField: true,
    },

    {
      type: "divider",
      nonValueField: true,
      uniqueId: uuid(),
      conditionals: [
        {
          parentId: "content-processing-advanced-mode-checkbox",
          parentIdValueProperty: "value",
          condition: true,
          type: "show",
        },
      ],
    },
    /*{
      type: "select",
      label: "Content Length",
      serviceKey: "textGenContentLength",
      uniqueId: "content-processing-content-length",
      description: "What frequency of emojis should the generated content have",
      defaultValue: "content-processing-content-length-short",
      conditionals: [
        {
          parentId: "content-processing-advanced-mode-checkbox",
          parentIdValueProperty: "value",
          condition: true,
          type: "show",
        },
      ],
      selectOptions: [
        {
          label: "Short",
          description:
            "Best for social media platforms such as Twitter/x and Facebook",
          value: "content-processing-content-length-short",
        },
        {
          label: "Medium",
          description: "Short descriptions, blog posts, and blurbs",
          value: "content-processing-content-length-medium",
        },
        {
          label: "Long",
          description: "Long form platforms such as blogging",
          value: "content-processing-content-length-long",
        },
      ],
      transformer: (x: any) => x,
    },*/
    {
      type: "select",
      label: "Emoji Level",
      serviceKey: "socialTextEmojiLevel",
      uniqueId: "content-processing-emoji-level",
      defaultValue: "content-processing-emoji-level-some",
      description: "What frequency of emojis should the generated content have",

      conditionals: [
        {
          parentId: "content-processing-advanced-mode-checkbox",
          parentIdValueProperty: "value",
          condition: true,
          type: "show",
        },
      ],
      selectOptions: [
        {
          label: "None",
          value: "content-processing-emoji-level-none",
        },
        {
          label: "Some",
          description: "At least a few emojis",
          value: "content-processing-emoji-level-some",
        },
        {
          label: "A lot",
          description: "Go crazy with emojis throughout the content",
          value: "content-processing-emoji-level-alot",
        },
      ],
      transformer: (x: any) => x,
    },
    {
      type: "select",
      label: "Hashtag Level",
      uniqueId: "content-processing-hashtag-level",
      defaultValue: "content-processing-hashtag-level-some",
      serviceKey: "socialTextHashtagLevel",
      description:
        "What frequency of hashtags should the generated content have",

      conditionals: [
        {
          parentId: "content-processing-advanced-mode-checkbox",
          parentIdValueProperty: "value",
          condition: true,
          type: "show",
        },
      ],
      selectOptions: [
        {
          label: "None",
          value: "content-processing-hashtag-level-none",
        },
        {
          label: "Some",
          description: "At least a few",
          value: "content-processing-hashtag-level-some",
        },
        {
          label: "A lot",
          description: "Go crazy with them throughout the content",
          value: "content-processing-hashtag-level-alot",
        },
      ],
      transformer: (x: any) => x,
    },
    //
    {
      type: "select",
      label: "Content Writing Style",
      uniqueId: "content-processing-writing-style",
      defaultValue: "content-processing-writing-style-default",
      serviceKey: "textWritingStyle",
      selectOptionVariant: "option",
      description:
        "What writing style and voice should the content writer have? For videos this will affect the text that is narrated.",
      conditionals: [
        {
          parentId: "content-processing-advanced-mode-checkbox",
          parentIdValueProperty: "value",
          condition: true,
          type: "show",
        },
      ],

      selectOptions: [
        {
          label: "Default",
          value: "content-processing-writing-style-default",
          description: "A neutral writing style",
        },
        {
          label: "Marketing",
          value: "content-processing-writing-style-marketing",
          description: "Catered towards sales, marketing, and promotion",
        },
        {
          label: "Roast Mode",
          value: "content-processing-writing-style-roast-mode",
          description:
            "Light-hearted and playful, makes jokes and uses exaggeration about the subject",
        },
        {
          label: "Upbeat",
          value: "content-processing-writing-style-upbeat",
          description:
            "Exudes positivity and energy. Incorporating optimistic language and a cheerful tone.",
        },
        {
          label: "Old and Wise",
          value: "content-processing-writing-style-old-wise",
          description:
            "Speaks from experience. Offering timeless wisdom or advice. A guru that has wise wisdom about the subject",
        },
        {
          label: "Friendly and Wholesome",
          value: "content-processing-writing-style-friendly-wholesome",
          description:
            "Radiates warmth and kindness. promoting positivity and a sense of community, using language that is supportive to make everyone feel welcome",
        },
        {
          label: "Sarcastic",
          value: "content-processing-writing-style-sarcastic",
          description:
            "Uses irony and exaggeration. The intended meaning of the words sometimes used is opposite to their literal meaning",
        },
      ],
      transformer: (x: any) => x,
    },
    {
      type: "divider",
      uniqueId: uuid(),
      nonValueField: true,
    },
    {
      type: "toggle",
      uniqueId: "content-processing-image-checkbox",
      text: "Generate Images",
      serviceKey: "shouldGenerateImages",
      required: false,
      description: "Do you want the generated content to have an image",
      nonValueField: true,
    },
  ],
};

export default simpleContentProcessingStep;
