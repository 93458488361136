import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import useFeatureFlags, { FeatureFlags } from "../hooks/useFeatureFlags";


interface Props{
    children: any
}
export const ProtectedRoute = ({ children }:Props) => {
  const { loggedIn } = useContext(AuthContext);
  const {isFeatureEnabled} = useFeatureFlags();


  if (!loggedIn) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }

  if(!isFeatureEnabled(FeatureFlags.BETA_ACCESS) && loggedIn){
    return <Navigate to="/beta" />;
  }

  return loggedIn && isFeatureEnabled(FeatureFlags.BETA_ACCESS) && children;
};