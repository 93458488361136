import "@cloudscape-design/global-styles/index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthContextProvider } from "./contexts/AuthContext";
import '@mantine/core/styles.css';
import { MantineProvider } from "@mantine/core";
import TWAppLayout from "./components/layout/TWAppLayout";
function App() {
  return (
    <>
      <GoogleOAuthProvider clientId="1037772565642-r8qqvlc4vvomb3ops34njuv46clsgisn.apps.googleusercontent.com">
      <MantineProvider>
          <AuthContextProvider>

            <TWAppLayout />
          </AuthContextProvider>
          </MantineProvider>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
