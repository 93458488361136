import { Box } from "@cloudscape-design/components";
import { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode;
}

const RightAlignedContentWrapper = styled(Box)`
  margin-left: auto;
  margin-right: 0;
  display: table;
`;

const RightAlignedContent = ({ children }: Props) => {
  return <RightAlignedContentWrapper>{children}</RightAlignedContentWrapper>;
};

export default RightAlignedContent
