import { ReactNode, createContext, useEffect, useState } from "react";
import { getUniqueIdForField, isStepValid } from "./formGeneratorUtils";
import { FormFieldItem, FormStep } from "./formGeneratorConstants";
export interface StringKeyArrayValue {
  [key: string]: any[];
}
export interface StringKeyAnyValue {
  [key: string]: any;
}
export interface ValidateStepResult {
  isValid: boolean;
  errors: StringKeyArrayValue;
}
export interface LabelDescriptionValue {
  label?: string;
  labelDescription?: string;
  value?: any;
  valueDescription?: string;
}
export interface KeyLabelDescriptionValue {
  [key: string]: LabelDescriptionValue;
}
interface IFormContext {
  setHumanReadableFormValues: React.Dispatch<KeyLabelDescriptionValue>;
  humanReadableFormValues: KeyLabelDescriptionValue;
  setActiveStepIndex: React.Dispatch<React.SetStateAction<number>>
  activeStepIndex:number;
  setRawFormValues: React.Dispatch<StringKeyAnyValue>;
  rawFormValues: StringKeyAnyValue;
  setServiceNamedFormValues: React.Dispatch<any>;
  serviceNamedFormValues: any;
  setFormErrors: React.Dispatch<any>;
  formErrors: StringKeyArrayValue;
  setFormOnChangeHandlers: React.Dispatch<any>;
  formOnChangeHandlers: any;
  getFieldOnChange: (field: any) => (detail: any) => void;
  validateStep: (step: FormStep) => Promise<ValidateStepResult>;
  validateAllSteps: (formSteps: FormStep[]) => Promise<ValidateStepResult[]>;
  onSubmit:  (res: ValidateStepResult[]) => Promise<any>
}
export const FormContext = createContext<IFormContext>({
  setHumanReadableFormValues: () => {},
  humanReadableFormValues: {},
  setActiveStepIndex: () => {},
  activeStepIndex: 0,
  setRawFormValues: () => {},
  rawFormValues: {},
  setServiceNamedFormValues: () => {},
  serviceNamedFormValues: {},
  setFormErrors: () => {},
  formErrors: {},
  setFormOnChangeHandlers: () => {},
  onSubmit: async () => {},
  validateStep: () =>
    Promise.resolve({
      isValid: true,
      errors: {},
    }),
  validateAllSteps: (formSteps: FormStep[]) => Promise.resolve([]),
  formOnChangeHandlers: {},
  getFieldOnChange: () => () => {}
  
});

interface Props {
  children: ReactNode;
  onSubmit: (res: ValidateStepResult[]) => Promise<any>
}
const FormContextProvider = ({ children, onSubmit }: Props) => {
  const [rawFormValues, setRawFormValues] = useState<StringKeyAnyValue>({});
  
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [humanReadableFormValues, setHumanReadableFormValues] =
    useState<StringKeyAnyValue>({});
  const [serviceNamedFormValues, setServiceNamedFormValues] = useState<any>({});
  const [formErrors, setFormErrors] = useState<any>({});
  const [formOnChangeHandlers, setFormOnChangeHandlers] = useState<any>({});

  const validateStep = (step: FormStep) => {
    return isStepValid(rawFormValues, step).then((x) => {
      setFormErrors((prev: any) => ({ ...prev, ...x.errors }));
      return x;
    });
  };

  const validateAllSteps = async (formSteps: FormStep[]) => {
    const promises: Promise<ValidateStepResult>[] = formSteps.map((step) =>
      validateStep(step)
    );
    return Promise.all(promises);
  };

  const getFieldOnChange = (field: FormFieldItem) => {
    const fieldType = field.type;
    const fieldKey = getUniqueIdForField(field);
    const serviceNameFieldKey = field.serviceKey || getUniqueIdForField(field);

    if (formOnChangeHandlers[fieldKey] != null) {
      return formOnChangeHandlers[fieldKey];
    }
    return ({ detail }: any) => {
      let humanReadableValue: LabelDescriptionValue = {};
      let rawVal = {};
      switch (fieldType) {
        case "select":
          //console.log(detail);
          rawVal = detail?.selectedOption;
          humanReadableValue = {
            value: detail?.selectedOption?.label,
            valueDescription: detail?.selectedOption?.description,
          };
          break;
        case "checkbox":
        case "toggle":
          rawVal = detail?.checked;
          humanReadableValue = { value: detail?.checked };
          break;
        case "fileUpload":
          rawVal = detail?.file;
          humanReadableValue = { value: detail?.file };
          break;

        case "number":
          if (field.regex != null && !RegExp(field.regex).test(detail.value)) {
            return;
          }
          rawVal = detail?.value;
          humanReadableValue = { value: detail?.value };
          break;
        case "input":
        case "textarea":
        case "radio":
        case "url":
        case "email":
        default:
          rawVal = detail?.value;
          humanReadableValue = { value: detail?.value };

          break;
      }

      setServiceNamedFormValues((prev: any) => ({
        ...prev,
        [serviceNameFieldKey]: rawVal,
      }));

      const humanReadableKeyValue: LabelDescriptionValue = {
        ...humanReadableValue,
        label: field.label,
        labelDescription: field.description,
      };

      //console.log(humanReadableKeyValue);
      setHumanReadableFormValues((prev: any) => ({
        ...prev,
        [fieldKey]: humanReadableKeyValue,
      }));
      setRawFormValues((prev: any) => ({ ...prev, [fieldKey]: rawVal }));
    };
  };



  return (
    <FormContext.Provider
      value={{
        onSubmit,
        humanReadableFormValues,
        setHumanReadableFormValues,
        rawFormValues,
        setRawFormValues,
        formErrors,
        setFormErrors,
        formOnChangeHandlers,
        setFormOnChangeHandlers,
        getFieldOnChange,
        serviceNamedFormValues,
        setServiceNamedFormValues,
        validateStep,
        validateAllSteps,
        activeStepIndex,
        setActiveStepIndex
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContextProvider;
