import {
  Checkbox,
  Input,
  Multiselect,
  RadioGroup,
  Select,
  Textarea,
  Toggle,
} from "@cloudscape-design/components";
import { getUniqueIdForField, showFieldOrStep } from "./formGeneratorUtils";
import { useContext, useEffect, useMemo, useState } from "react";
import { FormContext } from "./FormContext";
import { FormFieldItem, SelectOptionItem } from "./formGeneratorConstants";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styled from "styled-components";
import WizardStepGenerator from "./WizardStepGenerator";

interface GeneratedFieldProps {
  field: FormFieldItem;
  onChangeFn: (val: any) => void;
}

const StyledSelect = styled(Select)`
  [class^="awsui_dropdown-content"]{
    max-height: 35vh !important;
    max-width: 75vw !important;
  }
  [class^="awsui_options-list"]{
    max-height: 35vh !important;
    max-width: 75vw !important;
  }
  
`
const GeneratedField = ({ field, onChangeFn }: GeneratedFieldProps) => {
  const uniqueId = getUniqueIdForField(field);
  const { rawFormValues } = useContext(FormContext);
  const [defaultValue, setDefaultValue] = useState<any | undefined>();

  useEffect(() => {
    if (!showFieldOrStep(field, rawFormValues)) {
      return;
    }

    let defaultVal = undefined;
    let defaultValEvent = undefined;

    switch (field.type) {
      case "select":
        const defaultSelectOpton = field.selectOptions?.filter(
          (x: SelectOptionItem) => x.value === field.defaultValue
        )?.[0];

        if (defaultSelectOpton != null) {
          defaultValEvent = { detail: { selectedOption: defaultSelectOpton } };
        }
        defaultVal = defaultSelectOpton;
        break;

      case "email":
      case "url":
      case "input":
      case "number":
      case "textarea":
        if (field.defaultValue != null) {
          defaultValEvent = { detail: { value: field.defaultValue } };
        }
        defaultVal = field.defaultValue;
        break;
      case "checkbox":
      case "toggle":
        if (field.defaultValue != null) {
          defaultValEvent = { detail: { checked: field.defaultValue } };
        }
        defaultVal = field.defaultValue;
        break;
      case "radio":
      case "fileUpload":
      case "markdown":
      case "multiselect":
      case "divider":
      default:
        break;
    }

    if (defaultValEvent != null && rawFormValues[field.uniqueId] == null) {
      onChangeFn(defaultValEvent);
    }
    setDefaultValue(defaultVal);
    //return defaultVal
  }, []);

  const returnNode = useMemo(() => {
    switch (field.type) {
      case "step":
        return field?.formStepDefinition != null ? <WizardStepGenerator formStepDefinition={field.formStepDefinition} /> : null
      case "textarea":
        return (
          <Textarea
            key={uniqueId}
            placeholder={field.placeholder}
            onChange={onChangeFn}
            disabled={field.disabled}
            
            rows={7}
            value={rawFormValues[uniqueId] || defaultValue || ""}
          />
        );
      case "multiselect":
        return (
          <Multiselect
            key={uniqueId}
            filteringType="auto"
            onChange={onChangeFn}
            disabled={field.disabled}
            selectedOptions={rawFormValues[uniqueId] || defaultValue || []}
            options={field.selectOptions}
          />
        );
      case "radio":
        return (
          <RadioGroup
            key={uniqueId}
            value={rawFormValues[uniqueId]}
            items={field.radioOptions}
            onChange={onChangeFn}
          />
        );
      case "checkbox":
        return (
          <Checkbox
            key={uniqueId}
            disabled={field.disabled}
            checked={rawFormValues[uniqueId] || defaultValue || false}
            onChange={onChangeFn}
          >
            {field.text}
          </Checkbox>
        );
      case "select":
        return (
          <StyledSelect
            key={uniqueId}
            onChange={onChangeFn}
            disabled={field.disabled}
            selectedOption={rawFormValues[uniqueId] || defaultValue}
            triggerVariant={field.selectOptionVariant ?? "option"}
            options={field.selectOptions}
          />
        );
      case "fileUpload":
        return <></>;
      case "markdown":
        return (
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {field.text ?? ""}
          </ReactMarkdown>
        );
      case "toggle":
        return (
          <Toggle
            onChange={onChangeFn}
            disabled={field.disabled}
            checked={rawFormValues[uniqueId] ||  defaultValue || false}
          >
            {field.text}
          </Toggle>
        );
      case "email":
        return (
          <Input
            type="email"
            placeholder={field.placeholder}
            disabled={field.disabled}
            value={rawFormValues[uniqueId] || defaultValue}
            onChange={onChangeFn}
          />
        );
      case "url":
        return (
          <Input
            type="url"
            placeholder={field.placeholder}
            disabled={field.disabled}
            value={rawFormValues[uniqueId] || defaultValue}
            onChange={onChangeFn}
          />
        );
      case "input":
        return (
          <Input
            placeholder={field.placeholder}
            disabled={field.disabled}
            value={rawFormValues[uniqueId] || defaultValue}
            onChange={onChangeFn}
          />
        );
      case "number":
        return (
          <Input
            type="number"
            placeholder={field.placeholder}
            disabled={field.disabled}
            value={rawFormValues[uniqueId] || defaultValue}
            onChange={onChangeFn}
          />
        );
      case "divider":
        return <hr />;
      default:
        return null;
    }
  }, [uniqueId, onChangeFn, rawFormValues, field]);

  return returnNode;
};

export default GeneratedField;
