import { FormStep } from "../../wizardGenerator/formGeneratorConstants";

const imageOptionsStep: FormStep = {
  type: "section",
  text: "Image Generation Options",
  conditionals: [
    {
      parentId: "content-processing-image-checkbox",
      condition: true,
      type: "required",
    },
    {
      parentId: "content-processing-image-checkbox",
      condition: true,
      type: "show",
    },
  ],
  items: [
    {
      type: "number",
      label: "Number of Images",
      uniqueId: "content-processing-image-number",
      description: "A number between 1 and 4. How many images should your content have?",
      regex: "^[1-4]$",
      serviceKey: "numberOfImagesToGenerate",
      disabled:true,
      defaultValue: 4,
    },
    {
        type: "select",
        label: "Image Style",
        uniqueId: "content-processing-image-style",
        serviceKey: "imageStyle",
        defaultValue: "content-processing-image-style-neutral",
        description:
          "The style of the images to be generated",
  

        selectOptions: [
          {
            label: "Default",
            description: "Neutral - no specific styling",
            value: "content-processing-image-style-neutral"
          },
          {
            label: "Cartoon",
            options: [
              {
                label: "Anime",
                value: "content-processing-image-style-cartoon-anime",
                serviceValue: "cartoon-anime",
                tag: "cartoon",
              },
              {
                label: "Vibrant Colors",
                value: "content-processing-image-style-cartoon-vibrant-colors",
                serviceValue: "cartoon-vibrant-colors",
                tag: "cartoon",
              },
              {
                label: "Childrens Story",
                value: "content-processing-image-style-cartoon-childrens-story",
                serviceValue: "cartoon-childrens-story",
                tag: "cartoon",
              },
              {
                label: "Realistic",
                value: "content-processing-image-style-cartoon-realistic",
                serviceValue: "cartoon-realistic",
                tag: "cartoon",
              },
            ],
          },
          {
            label: "Hyper Realistic",
            options: [
              {
                label: "Cyberpunk",
                value: "content-processing-image-style-hyper-realistic-cyberpunk",
                serviceValue: "hyper-realistic-cyberpunk",
                tag: "hyper-realistic",
              },
              {
                label: "Vibrant Colors",
                value: "content-processing-image-style-hyper-realistic-vibrant",
                serviceValue: "hyper-realistic-vibrant",
                tag: "hyper-realistic",
              },
              {
                label: "Dark and Moody",
                value: "content-processing-image-style-hyper-realistic-dark-moody",
                serviceValue: "hyper-realistic-dark-moody",
                description: "detailed photograph shot, dark lighting",
                tag: "hyper-realistic",
              },
              {
                label: "35mm film movie still",
                description:"Ultra photorealistic, cold muted colors, sharp focus, dramatic lighting",
                serviceValue: "hyper-realistic-dark-35mm",
                value: "content-processing-image-style-hyper-realistic-35mm",
                tag: "hyper-realistic",
              },
            ],
          },
          {
            label: "Painting",
            options: [
              {
                label: "Detailed Matte",
                value: "content-processing-image-style-painting-matte",
                serviceValue: "painting-matte",
                tag: "painting",
              },
              {
                label: "Minimalist",
                value: "content-processing-image-style-painting-minimalist",
                serviceValue: "painting-minimalist",
                tag: "painting",
              },
              {
                label: "Geometric",
                value: "content-processing-image-style-painting-geometric",
                serviceValue: "painting-geometric",
                tag: "painting",
              },
              {
                label: "Surrealism",
                value: "content-processing-image-style-painting-surrealism",
                serviceValue: "painting-surrealism",
                tag: "painting",
              },
              {
                label: "Details Oil",
                value: "content-processing-image-style-painting-oil",
                serviceValue: "painting-oil",
                tag: "painting",
              },
            ],
          },
          {
            label: "Misc",
            disabled: false,
            options: [
              {
                label: "Claymation",
                value: "content-processing-image-style-claymation",
                serviceValue: "claymation",
                description: "The subject is formed from clay as if they were in a stop motion claymation video",
                tag: "misc",
              },
              {
                label: "Puppet",
                value: "content-processing-image-style-puppet",
                serviceValue: "puppet",
                description: "Neutral colors, the subject is styled as a felt puppet",
                tag: "misc",
              },
              {
                label: "Youtube Thumbnail",
                value: "content-processing-image-style-youtube-thumbnail",
                serviceValue: "youtube-thumbnail",
                description: "Bright vibrant colors, the subject is imposed in front of a background image",
                tag: "misc",
              },
            ],
          },
        ],
        transformer: (x: any) => x,
      },
  ],
};

export default imageOptionsStep;
