import { FormStep } from "../formGeneratorConstants";
import DividerBetween from "../../../layout/DividerBetween";
import ReviewStepSection from "./ReviewStepSection";
import { isValidElement, useContext } from "react";
import { showFieldOrStep } from "../formGeneratorUtils";
import { FormContext } from "../FormContext";

interface Props {
  //wizardDefinition: WizardDefintion;
  steps: FormStep[];
}

const ReviewStep = ({ steps }: Props) => {
  const {rawFormValues} = useContext(FormContext)
  
  return (
    <DividerBetween size="m">
      {steps.map((step, stepIdx) => 
        {
          const stepItems = step.items.filter(
            (item) =>
              (item.nonValueField == null || !item.nonValueField) &&
              showFieldOrStep(item, rawFormValues)
          )
        
        
        
          if (stepItems.length === 0) {
            return null;
          }
          return (
            <ReviewStepSection key={step.uniqueId ?? stepIdx} stepItems={stepItems} step={step} />
          );
        }
      )}
    </DividerBetween>
  );
};

export default ReviewStep;
