import { useMemo } from "react";

const useToolsConfig = () => {
  const toolsConfig = useMemo(() => {
    return [
        {
            category: "Generation",
            title: "Transcribe",
            id: "text-transcribe",
            href: "/tools/text/transcribe",
            enabled: true
        },
        {
            category: "Generation",
            title: "Summarize",
            id: "text-summarize",
            href: "/tools/text/summarize",
            enabled: true
        },

        {
            category: "Content Creation",
            title: "Social Media Post",
            id: "content-smp",
            href: "/tools/content/social-media-post",
            enabled: true
        },
        {
            category: "Content Creation",
            title: "Blog Article",
            id: "content-blog",
            href: "/tools/content/blog-article",
            enabled: true
        },
        {
            category: "Image",
            title: "Text to Image",
            id: "image-t2i",
            href: "#",
            enabled: true
        },
        {
            category: "Image",
            title: "Image to Image",
            id: "image-i2i",
            href: "#",
            enabled: true
        },
        {
            category: "Image",
            title: "Image Infill",
            id: "image-infill",
            href: "#",
            enabled: true
        },
        {
            category: "Image",
            title: "Image Extend",
            id: "image-extend",
            href: "#",
            enabled: true
        },
        {
            category: "Video",
            title: "Narrated Story",
            id: "video-ns",
            href: "#",
            enabled: true
        },
        {
            category: "Video",
            title: "Explainer",
            id: "video-explainer",
            href: "#",
            enabled: true
        },
    ]
  }, []);

  const categorizedToolsConfig = useMemo(() => {

    const categorized : any = {}

    toolsConfig.forEach(x => {
      if(!(x.category in categorized)){
        categorized[x.category] = []
      }
      categorized[x.category].push(x)
    })
    return categorized
  }, [toolsConfig]);
  return { toolsConfig, categorizedToolsConfig };
};

export default useToolsConfig;
