import {  useMemo } from "react";
import WizardStepGenerator from "./WizardStepGenerator";
import { FormStep } from "./formGeneratorConstants";
import ReviewStep from "./review/ReviewStep";

interface Props {
  currentStep: FormStep;
  stepsArr: FormStep[];
}

const WizardStepBody = ({ currentStep, stepsArr }: Props) => {
  const nonReviewSteps = useMemo(
    () => stepsArr.filter((x) => x.type !== "review"),
    [stepsArr]
  );

  if (currentStep.type === "review") {
    return <ReviewStep steps={nonReviewSteps} />;
  }
  return <WizardStepGenerator formStepDefinition={currentStep} />;
};

export default WizardStepBody;
