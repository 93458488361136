import {
  ContentLayout,
  Header,
} from "@cloudscape-design/components";
import SingleFieldInputForm from "../common/SingleFieldInputForm";
import { getApiDomain } from "../../../config/envConfig";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useApiRequest from "../../../hooks/useApiRequest";

const YouTubeSummarize = () => {
  const {apiRequest} = useApiRequest()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const submit = (val: string) => {
    setIsLoading(true)
    apiRequest(`${getApiDomain()}/jobs`,'POST',{ youtubeUrl: val, type: "YouTubeSummarize"})
    .then((resp) => {
      navigate(`/tools/jobs/${resp.id}`)
    })
    .finally(() => {
      setIsLoading(false)
    })
    console.log(val)
  };
  return (
    <ContentLayout header={<Header>YouTube Video Summarize</Header>}>
      <SingleFieldInputForm isLoading={isLoading} onSubmit={submit} label="YouTube Video Url" />
    </ContentLayout>
  );
};

export default YouTubeSummarize;
