import React, { useContext, useMemo } from "react";
import SideNavigation, {
  SideNavigationProps,
} from "@cloudscape-design/components/side-navigation";
import useToolsConfig from "../../hooks/useToolsConfig";
import { useNavigate } from "react-router";
import { AuthContext } from "../../contexts/AuthContext";

const SideNav = () => {
  const [activeHref, setActiveHref] = React.useState("#/page1");
  const { categorizedToolsConfig } = useToolsConfig();
  const { loggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const toolItems: SideNavigationProps.Item[] = useMemo(() => {
    return Object.entries(categorizedToolsConfig).map((entry: any) => {
      const category: any = entry[0];
      const items: any = entry[1];
      return {
        defaultExpanded: true,
        type: "section",
        text: category,
        items: items.map((item: any) => {
          return {
            type: "link",
            href: item.href ?? "#",
            text: item.title,
          };
        }),
      };
    });
  }, [categorizedToolsConfig]);

  if (!loggedIn) {
    return null;
  }

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: "/", text: "TaskWork.Ai" }}
      onFollow={(event) => {
        if (!event.detail.external) {
          event.preventDefault();
          navigate(event.detail.href);
          setActiveHref(event.detail.href);
        }
      }}
      items={[
        {
          type: "link",
          text: "My jobs",
          href: "/tools/jobs",
        },
        { type: "divider" },
        ...toolItems,
      ]}
    />
  );
};

export default SideNav;
