import { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";

const useApiRequest = () => {
  
  const [isRequestInFlight, setIsRequestInFlight] = useState(false);
  const { idToken } = useContext(AuthContext)
  const apiRequest = async (url:string, method: 'GET' | 'POST', body?: any) => {
    let requestParams : any = {
      method,
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authentication": `Bearer ${idToken}`
      }
    }

    if(body != null){
      try{
        requestParams.body = JSON.stringify(body)
      }catch{}
    }


    try{
      setIsRequestInFlight(true)
      const response = await fetch(url,requestParams)
      return JSON.parse(await response.text())
    }catch(e){
      console.log(e)
      return {}
    }finally{
      
      setIsRequestInFlight(false)
    }

  }

  return { apiRequest , isRequestInFlight };
};

export default useApiRequest;
