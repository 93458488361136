export const getApiDomain = () => {
    console.log(window.location.hostname)
    switch(window.location.hostname){
        
        case 'gentoo.ai':
            return 'https://api.gentoo.ai'
            
        case 'taskwork.ai':
        case 'www.taskwork.ai':
            return 'https://api.taskwork.ai'
        case 'localhost':
        default:
            return 'http://localhost:5010'
    }

}