import {
  Box,
  Button,
  Container,
  FormField,
  Input,
} from "@cloudscape-design/components";
import { useState } from "react";

interface Props {
  label: string;
  onSubmit: (val: string) => void;
  isLoading?: boolean
}
const SingleFieldInputForm = ({ label, onSubmit, isLoading }: Props) => {
  const [videoUrl, setVideoUrl] = useState("");

  return (
    <Container
      footer={
        <Box textAlign="right">
          <Button
            loading={isLoading}
            
            onClick={() => {
              onSubmit(videoUrl);
            }}
          >
            Submit
          </Button>
        </Box>
      }
    >
      <FormField label={label}>
        <Input
          value={videoUrl}
          onChange={(e) => {
            setVideoUrl(e.detail.value);
          }}
        />
      </FormField>
    </Container>
  );
};

export default SingleFieldInputForm;
