import { FormStep } from "../../wizardGenerator/formGeneratorConstants"

const contentDestinationStep: FormStep = {
  type: "section",
  text: "Destinations",
  required: true,
  items: [
    {
      type: "select",
      label: "Destination",
      uniqueId: "content-destination-type-select",
      description:
        "Where should we get the send the resulting content? Regardless of the option picked, your content will always be available in the TaskWork Tools Dashboard.",
      required: false,

      selectOptions: [
        {
          label: "Social Media",
          disabled: true,
          options: [
            {
              label: "X/Twitter",
              value: "destination-social-twitter",
              tag: "social",
            },
            {
              label: "Instagram",
              value: "destination-social-instagram",
              tag: "social",
            },
          ],
        },
        {
          label: "Video",
          disabled: true,
          options: [
            {
              label: "Youtube",
              value: "destination-video-youtube",
              tag: "video",
            },
            {
              label: "Instagram Reels",
              value: "destination-video-instagram-reels",
              tag: "video",
            },
            {
              label: "Instagram Stories",
              value: "destination-video-instagram-reels",
              tag: "video",
            },
            {
              label: "Facebook Reels",
              value: "destination-video-instagram-stories",
              tag: "video",
            },
            {
              label: "Facebook Stories",
              value: "destination-video-instagram-stories",
              tag: "video",
            },
            {
              label: "TikTok",
              value: "destination-video-tiktok",
              tag: "video",
            },
          ],
        },
        {
          label: "Misc",
          disabled: false,
          options: [
            {
              label: "Wordpress",
              value: "destination-misc-wordpress",
              disabled: true,
              tag: "misc",
            },
            {
              label: "Webhook",
              value: "destination-misc-webhook",
              tag: "misc",
            },
            {
              label: "Email",
              value: "destination-misc-email",
              disabled: true,
              tag: "misc",
            },
          ],
        },
      ],
      transformer: (x: any) => x,
    },
    {
      type: "url",
      label: "Webhook URL",
      uniqueId: "destination-webhook-url-input",
      description: "The URL of the webhook that we will send your content to",
      placeholder: "https://webhook.site/",
      conditionals: [
        {
          parentId: "content-destination-type-select",
          condition: "destination-misc-webhook",
          parentIdValueProperty: "value",
          type: "required",
        },
        {
          parentId: "content-destination-type-select",
          condition: "destination-misc-webhook",
          parentIdValueProperty: "value",
          type: "show",
        },
      ],
      transformer: (x: any) => x,
    },
  ],
};

export default contentDestinationStep;
