import Faq from "../../mortal/src/components/Faq";
import AboutOne from "../../mortal/src/components/aboutOne";
import AboutTwo from "../../mortal/src/components/aboutTwo";
import AmazingFeatures from "../../mortal/src/components/amazingFeatures";
import Features from "../../mortal/src/components/features";

import { HomeHero } from "./HomeHero";

export const Home = () => {
  return (
    <>
      <HomeHero />
      <section className="relative md:py-24 py-16">
        <Features classlist="container relative" />
        <AboutOne />
        <AboutTwo />
        <AmazingFeatures />

        <Faq />
      </section>
    </>
  );
};
