import {
  Box,
  Container,
  ContentLayout,
  Header,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";
import { useContext, useEffect, useMemo, useState } from "react";
import useApiRequest from "../../../hooks/useApiRequest";
import { getApiDomain } from "../../../config/envConfig";
import { AuthContext } from "../../../contexts/AuthContext";
import JobSectionContainer from "./JobSectionContainer";


var groupBy = function (xs: any[], key: string) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const MyJobs = () => {
  const { apiRequest } = useApiRequest();
  const { loggedIn } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState<any[]>([]);
  useEffect(() => {
    if (!loggedIn) {
      return;
    }
    setLoading(true);
    apiRequest(`${getApiDomain()}/jobs`, "GET")
      .then((ret: any) => {
        if (ret.jobs == null || !Array.isArray(ret.jobs)) {
          return;
        }
        setJobs(ret.jobs);
        console.log(ret.jobs);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const grouped = useMemo(() => {
    const ret = groupBy(jobs, "status");
    console.log(ret);
    return ret;
  }, [jobs]);

  return (
    <ContentLayout header={<Header>My Jobs</Header>}>
      {jobs.length === 0 ? (
        <Container>
          <Box textAlign="center"><Spinner size="large" /></Box>
        </Container>
      ) : (
        <SpaceBetween size="m">
          {Object.entries(grouped).map((entry: any) => {
            const key = entry[0];
            const groupJobs: any[] = entry[1];
            return <JobSectionContainer headerText={key} jobs={groupJobs} />
          })}
        </SpaceBetween>
      )}
    </ContentLayout>
  );
};

export default MyJobs;
